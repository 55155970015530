.dtt-tabContainer {
    --tab-border: 3px solid var(--color-light-separator);

    position: relative;
    display: flex;
    height: 30px;
    border-left: var(--tab-border);
    border-bottom: var(--tab-border);
    width: 100%;
}

.ddt-tabElem {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ddt-tab {
    width: 135px;
    border: var(--tab-border);
    border-bottom: none;
    border-left: none;
    font-size: 17px;
    color: var(--color-primary);
}

.ddt-tabSelected {
    color: white;
    background-color: var(--color-primary);
}

.ddt-addBtn {
    position: absolute;
    right: 0;
    font-size: 14px;
    color: var(--color-dark-gray);
}

.dtt-table {
    width: 100%;
    padding-top: 0 !important;
}

.ddt-foot {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 25px;
    /* height: 28px; */
    font-size: 18px;
}
