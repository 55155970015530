.totp-display-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4f8;
    border-radius: 6px;
    padding: 5px;
    margin: 0 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.totp-token {
}
