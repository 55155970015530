.clientSettings-drawer {
    padding: 90px 100px;
}

.clientSettings-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* font-feature-settings: 'liga' off; */
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 10px;
}

.clientSettings-drawer h3 {
    padding-top: 20px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: var(--color-primary);
    text-align: left;
    /* margin-bottom: 10px; */
}

.clientSettings-drawer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.clientSettings-drawer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.clientSettings-info {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.clientSettings-info-deactivate {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    color: var(--color-dark-red);
}

.clientSettings-info-deactivate:hover {
    color: red !important;
}

.clientSettings-cancel {
    margin-top: 40px;
    margin-bottom: 10px !important;
}

.clientSettings-cancel span {
    width: 100px;
}

.clientSettings-entity {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-items: space-between;
    align-content: center;
    flex-direction: column;
}

.clientSettings-entity-name {
    text-align: left;
    display: flex;
    margin-bottom: 5px;
}

.clientSettings-entity-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientSettings-entity-ral {
    display: flex;
}

.clientSettings-entity-ral > div {
    display: flex;
    align-items: center;
}

.clientSettings-entity-ral > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.clientSettings-entity-ral > div > div:first-child {
    margin-right: 15px;
}
