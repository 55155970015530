.investment-details-component {
    margin: 20px 0px !important;
}

.investment-details-header {
    display: flex;
    align-items: center;
    height: 40px;
}

.investment-details-header-title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 600;
}

.investment-details-header-subtype {
    color: var(--color-dark-gray);
    font-size: 18px;
    font-weight: 400;
}

.full-width-investment-details-table {
    margin-top: 34px;
    width: 100%;
}

.full-width-investment-details-table table {
    width: 100%;
}

.full-width-investment-details-table tr td {
    font-size: 18px;
}

.full-width-investment-details-table tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 25px; */
    border-bottom: 0;
    height: 50px;
    align-items: center;
}

.investment-investment-details-tables {
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.investment-investment-details-tables table {
    /* width: calc(50% - 75px/2); */
    /* width: 40%; */
}

.investment-investment-details-tables tr td {
    font-size: 18px;
}

.investment-investment-details-tables tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 25px; */
    border-bottom: 0;
    height: 50px;
    align-items: center;
}

.investment-investment-details-icon {
    cursor: pointer;
    height: 17px;
    width: 17px;
    margin-left: 17px;
}

.investment-investment-details-divider {
    border-top: 1px solid #efefef;
    height: 0 !important;
}

@media (max-width: 480px) {
    .investment-investment-details-tables {
        display: inline;
    }

    .investment-investment-details-tables table {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .investment-investment-details-tables {
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .investment-investment-details-tables {
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
}
