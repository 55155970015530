.kvp_component {
}

.kvp_header {
    display: flex;
    font-size: 16px;
    margin-bottom: 5px;
    justify-content: space-around;
    width: calc(100% - 44px);
    width: 100%;
}

.kvp_headerItem {
    text-align: center;
}

.kvp_container {
    position: relative;
    height: 400px;
    background-color: var(--color-very-light-gray);
    border: 1px solid white;
    padding-bottom: 14px;
    padding-top: 14px;
}

.kvp_datum {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.kvp_datumItem {
    --pad: 14px;
    background-color: white;
    height: 30px;
    border-radius: 3px;
    margin-top: 8px;
    border: 1px solid var(--color-border);
    width: var(--kvp-item-width);
    display: flex;
    align-items: center;
    padding-left: 7px;
}

.kvp_newEntry-container {
    background-color: var(--color-very-light-gray);
    width: calc(100% - 40px);
    margin: auto;
    margin-top: 20px;
    border-radius: 3px;
}

.kvp_newEntry-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    height: 36px;
}

.kvp_newEntry-header img {
    --size: 15px;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
}

.kvp_newEntry-datum {
    padding-bottom: 9px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.kvp_newEntry-datumItem {
    background-color: white;
    height: 30px !important;
    border: 1px solid var(--color-border);
    border-radius: 3px !important;
    /* width: var(--kvp-item-width) !important; */
    width: 100% !important;
    margin-left: 7px;
    padding-left: 7px;
}
.kvp_cancel {
    width: 30px;
    height: 30px;
    margin-left: 7px;
    margin-right: 7px;
}

.kvp_newEntry-dataItem_error {
    border-color: var(--color-dark-red) !important;
}
