* {
    --mobile-header-height: 50px;
}

.mobile-header {
    display: none;
    z-index: 1000;
}

@media (max-width: 480px) {
    .mobile-header {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-primary);
        width: 100%;
        height: var(--mobile-header-height) !important;
    }

    .mobile-header .logo {
        position: absolute;
        width: calc(40% - 20px);
        margin: 15px;
        margin-bottom: 26px;
    }

    .mobile-header .hamburger {
        float: right;
        width: 10% !important;
        margin-top: 5px;
    }

    .mobile-menu {
        position: fixed;
        padding: 20px;
        padding-top: 0;
        text-align: left;
        height: 540px;
        top: 50px;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: var(--color-primary);
        z-index: 4000;
        animation: fadein 0.15s ease-in-out 0s 1 !important;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
    }

    .mobile-menu .logo {
        width: calc(100% - 20px);
        margin: 10px;
        margin-bottom: 26px;
    }

    .mobile-menu .link {
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
        padding: 0 5px;
        margin: 10px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: 330px;
        text-decoration: none;
    }

    .mobile-menu .link-active {
        background-color: var(--color-accent) !important;
        color: var(--color-primary) !important;
        font-weight: 500;
    }

    .mobile-menu-separator {
        background-color: rgba(200, 200, 200, 0.3);
        height: 1px;
        width: 100%;
        margin: 15px 0;
    }

    .mobile-menu-new-investment {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 400;
        padding: 0 10px;
        padding-left: 34px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-image: url('/public/images/icons/plus.png');
        background-position: 8px;
        background-size: 16px;
        background-repeat: no-repeat;
    }

    .mobile-menu .link:hover,
    .mobile-menu-new-investment:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .mobile-menu-bottom {
        position: absolute;
        bottom: 20px;
    }

    .clear {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .mobile-header {
        display: block;
        top: 0;
        left: 0;
        background: var(--color-primary);
        width: 100%;
        height: 70px !important;
    }

    .mobile-header .logo {
        position: absolute;
        width: calc(40% - 20px);
        margin: 15px;
        margin-bottom: 26px;
    }

    .mobile-header .hamburger {
        float: right;
        width: 10%;
        margin-top: 5px;
    }

    .mobile-menu {
        position: fixed;
        padding: 20px;
        padding-top: 0px;
        text-align: left;
        height: 540px;
        top: 50px;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: var(--color-primary);
        z-index: 4000;
        animation: fadein 0.15s ease-in-out 0s 1 !important;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
    }

    .mobile-menu .logo {
        width: calc(100% - 20px);
        margin: 10px;
        margin-bottom: 26px;
    }

    .mobile-menu .link {
        height: 30px;
        line-height: 30px;
        font-size: 22px;
        font-weight: 400;
        padding: 0 10px;
        margin: 10px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }

    .mobile-menu .link-active {
        background-color: var(--color-accent) !important;
        color: var(--color-primary) !important;
        font-weight: 500;
    }

    .mobile-menu-separator {
        background-color: rgba(200, 200, 200, 0.3);
        height: 1px;
        width: 100%;
        margin: 15px 0;
    }

    .mobile-menu-new-investment {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: 400;
        padding: 0 10px;
        padding-left: 34px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-image: url('/public/images/icons/plus.png');
        background-position: 8px;
        background-size: 20px;
        background-repeat: no-repeat;
    }

    .mobile-menu .link:hover,
    .mobile-menu-new-investment:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .mobile-menu-bottom {
        position: absolute;
        bottom: 20px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .mobile-header {
        display: block;
        top: 0;
        left: 0;
        background: var(--color-primary);
        width: 100%;
        height: 70px;
    }

    .mobile-header .logo {
        position: absolute;
        width: calc(35% - 20px);
        margin: 15px;
        margin-bottom: 26px;
    }

    .mobile-header .hamburger {
        float: right;
        width: 9%;
        top: 0;
        margin-top: 5px;
    }

    .mobile-menu {
        position: fixed;
        padding: 20px;
        padding-top: 0px;
        text-align: left;
        height: 540px;
        top: 70px;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: var(--color-primary);
        z-index: 4000;
        animation: fadein 0.15s ease-in-out 0s 1 !important;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
    }

    .mobile-menu .logo {
        width: calc(100% - 20px);
        margin: 10px;
        margin-bottom: 26px;
    }

    .mobile-menu .link {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: 400;
        padding: 0 10px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }

    .mobile-menu .link-active {
        background-color: var(--color-accent) !important;
        color: var(--color-primary) !important;
        font-weight: 500;
    }

    .mobile-menu-separator {
        background-color: rgba(200, 200, 200, 0.3);
        height: 1px;
        width: 100%;
        margin: 15px 0;
    }

    .mobile-menu-new-investment {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: 400;
        padding: 0 10px;
        padding-left: 34px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-image: url('/public/images/icons/plus.png');
        background-position: 8px;
        background-size: 20px;
        background-repeat: no-repeat;
    }

    .mobile-menu .link:hover,
    .mobile-menu-new-investment:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .mobile-menu-bottom {
        position: absolute;
        bottom: 20px;
    }

    .clear {
        display: none;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .mobile-header {
        display: block;
        top: 0;
        left: 0;
        background: var(--color-primary);
        width: 100%;
        height: 70px;
    }

    .mobile-header .logo {
        position: absolute;
        width: calc(28% - 20px) !important;
        margin: 15px;
        margin-bottom: 26px;
    }

    .mobile-header .hamburger {
        float: right;
        width: 7%;
        top: 0;
        margin-top: 5px;
    }

    .mobile-menu {
        position: fixed;
        padding: 20px;
        padding-top: 0px;
        text-align: left;
        height: 540px;
        top: 70px;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: var(--color-primary);
        z-index: 4000;
        animation: fadein 0.15s ease-in-out 0s 1 !important;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
    }

    .mobile-menu .logo {
        width: calc(100% - 50px);
        margin: 10px;
        margin-bottom: 26px;
    }

    .mobile-menu .link {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: 400;
        padding: 0 10px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }

    .mobile-menu .link-active {
        background-color: var(--color-accent) !important;
        color: var(--color-primary) !important;
        font-weight: 500;
    }

    .mobile-menu-separator {
        background-color: rgba(200, 200, 200, 0.3);
        height: 1px;
        width: 100%;
        margin: 15px 0;
    }

    .mobile-menu-new-investment {
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: 400;
        padding: 0 10px;
        padding-left: 34px;
        margin: 5px 0;
        color: white;
        clear: both;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-image: url('/public/images/icons/plus.png');
        background-position: 8px;
        background-size: 20px;
        background-repeat: no-repeat;
    }

    .mobile-menu .link:hover,
    .mobile-menu-new-investment:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .mobile-menu-bottom {
        position: absolute;
        bottom: 20px;
    }

    .clear {
        display: none;
    }
}
