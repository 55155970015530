.investment-performance-summary-header {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 600;
}

.full-width-performance-summary-table {
    margin-top: 34px;
    width: 100%;
}

.full-width-performance-summary-table table {
    width: 100%;
}

.full-width-performance-summary-table tr td {
    font-size: 18px;
}

.full-width-performance-summary-table tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 0;
}

.investment-performance-summary-tables {
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.investment-performance-summary-tables table {
    width: calc(50% - 75px / 2);
}

.investment-performance-summary-tables tr td {
    font-size: 18px;
}

.investment-performance-summary-tables tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 0;
}

.investment-performance-summary-icon {
    cursor: pointer;
    height: 17px;
    width: 17px;
    margin-left: 17px;
}

.investment-performance-summary-divider {
    border-top: 1px solid #efefef;
    height: 0 !important;
}

@media (max-width: 480px) {
    .investment-performance-summary-tables {
        display: inline;
    }

    .investment-performance-summary-tables table {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .investment-performance-summary-tables {
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .investment-performance-summary-tables {
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
}
