.react-datepicker__input-container input {
    position: relative;
    height: 30px;
    /* height: 30px !important; */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 110px !important;
    font-size: 24px !important;
    color: var(--color-dark-gray);
}

.react-datepicker__input-container input {
    border-radius: 0 !important;
}

.react-datepicker__navigation {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}
