.docProcess-container {
    position: relative;
    /* height: 90%; */
    /* background-color: cyan; */
}

.social_twitter path {
    /* fill: var(--color-twitter); */
    fill: #1da1f2;
}

.social_facebook path {
    /* fill: var(--color-twitter); */
    fill: #1877f2;
}

.social_linkedin path {
    /* fill: var(--color-twitter); */
    fill: #0077b5;
}

.social_instagram path {
    /* fill: var(--color-twitter); */
    fill: #e1306c;
}
