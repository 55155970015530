.main {
    position: relative;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tableContainer {
        // background-color: orange;
        width: 100%;

        .table {
            // background-color: green;
            position: relative;
            max-width: 100%;
            overflow-x: auto;
            display: flex;
            padding: 0 0px;
            padding-bottom: 200px;

            table {
                // background-color: blue;
                min-width: unset;
                border-collapse: separate;
                border-spacing: 0;
                border-left: 1px solid var(--color-light-gray);

                th,
                td {
                    white-space: nowrap;
                    border: 1px solid var(--color-light-gray);
                    border-left: none;
                    text-align: center;
                    padding: 3px 15px;
                }

                .pendingRow {
                }

                .deletedRow {
                    background-color: var(--color-lighter-gray);
                }

                .erroredRow {
                    background-color: var(--color-dark-red-half);
                }

                .submittedRow {
                    background-color: var(--color-green-half);
                }
            }
        }

        .tableButtons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        button {
            width: min-content;
            margin: 0;
            padding: 0 10px;
            height: 30px;
        }

        .submitButton {
            background-color: var(--color-green);
        }

        .unsubmitButton {
            background-color: var(--color-blue);
        }

        .red {
            color: var(--color-dark-red);
        }

        .green {
            color: var(--color-green);
        }

        .orange {
            color: var(--color-orange);
        }
    }
}
