.iconTextButton {
    display: inline-flex;
    margin-left: 20px;
    font-size: 14px !important;
    color: var(--color-dark-gray);
    font-weight: 400;
    align-items: center;
    cursor: pointer;
}

.iconTextButton img {
    margin-right: 8px;
    width: 15px;
    height: 15px;
}
