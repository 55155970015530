.callNotices_component {
    --font-color-darkGray: var(--color-dark-gray);
    --font-color-lightGray: #969696;
    --pad: 15px;
    --circle-size: 17px;

    --metWidth: var(--circle-size);
    --dueDateWidth: 60px;
    --infoWidth: calc(100% - var(--circle-size) - var(--dueDateWidth) - var(--pad) * 3);
}

/* header */
.callNotices_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.callNotices_headerText {
    font-size: 16px;
    color: var(--color-primary);
    width: 80%;
    font-weight: 700;
}

.callNotices_headerSeeAll {
    color: var(--color-link);
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.callNotices_headerSeeAll:hover {
    color: var(--color-link-hover);
}

.callNotices_seeAll span {
    cursor: pointer;
}

/* table */
.callNotices_met {
    font-size: 12px;
    color: var(--font-color-lightGray);
    margin-bottom: 5px;
    margin-top: 12px;
}

/* row */
.callNotices_row {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-bottom: var(--pad);
}

.callNotices_row_metContainer {
    display: flex;
    align-items: center;
    margin-right: calc(var(--pad));
}

.callNotices_row_metCircle {
    font-weight: 400px;
    width: var(--circle-size);
    height: var(--circle-size);
    aspect-ratio: 1;
    border: 1px solid var(--color-link);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.callNotices_row_metCircleCheckContainer {
    width: 100%;
    height: 100%;
    background-color: var(--color-link);
    display: flex;
    align-items: center;
    justify-content: center;
}

.callNotices_row_metCircleCheck {
    --border-thickness: 2px;
    border-left: var(--border-thickness) solid white;
    border-bottom: var(--border-thickness) solid white;
    width: 8px;
    height: 3.5px;
    margin-bottom: 2px;
    margin-left: 1px;
    transform: rotate(-45deg);
}

.callNotices_row_info {
    margin-top: 5px;
    /* margin-left: 5px; */
    line-height: 20px;
    flex-direction: column;
    width: var(--infoWidth);
}

.callNotices_row_info div:first-child {
    color: var(--font-color-darkGray);
    font-size: 16px;
}

.callNotices_row_info div:last-child {
    color: var(--font-color-lightGray);
    font-size: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.callNotices_row_dueDate {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: right;

    width: var(--dueDateWidth);
    height: auto;
    height: 100%;
    right: 0;

    color: var(--font-color-darkGray);
    font-size: 14px;
}

.callNotices_row_dueDateHighlight {
    font-weight: 700;
    color: #5891f0;
}

.callNotices_row_dueDatePast {
    font-weight: 700;
    color: #eba524;
}
