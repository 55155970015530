.docjobs-container .pagTable {
    margin: 40px;
}

.docjobs-container .pagTable_headerText h1 {
    margin-bottom: 10px;
}

.docjobs-container div h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-dark-gray);
}

.docjobs-container div label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.docjobs-container div input {
    width: 30%;
}

.docjobs-table-container {
    margin-top: 40px;
}

.docjobs-table-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* width: 400px; */
}

.docjobs-row-ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    margin: 0;
}

.docjobs-row-read {
}

.docjobs-row-unread {
    font-weight: 800;
    background-color: white;
    border: 1px solid var(--color-lighter-gray);
    border-left: none;
    border-right: none;
}

.docjobs-table-header {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-dark-gray);
    display: flex;
    flex-direction: row;
}

.docjobs-select {
    margin: 0 5px;
}
