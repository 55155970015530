.rals_container {
    position: relative;
}

.rals_filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 60px 20px 60px;
    padding-top: 20px;
}

.ral_signedIcon.not-signed path {
    color: var(--color-dark-red);
}

.ral_signedIcon.signed path {
    color: var(--color-dark-green);
}
