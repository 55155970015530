$iconMarginLeft: 28px;

.main {
    position: relative;
    // background-color: cyan;
    margin: 40px 40px;

    .header {
        h1 {
            font-weight: bold;
            color: var(--color-primary);
            margin-bottom: 20px;
        }
    }

    .filterTop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .header {
            width: 200px;
            h1 {
                font-weight: bold;
                color: var(--color-primary);
                margin-bottom: 20px;
            }
        }

        .filters {
            width: calc(100%);
            display: flex;
            align-items: center;
            justify-content: right;
            flex-wrap: wrap;
        }

        .headerFilters {
            z-index: 2;
            width: calc(100% - 200px);
        }

        .filterInfo {
            // position: absolute;
            // right: 0;
            // top: 0;
            margin-left: 20px;
        }
    }

    .altxEmail path {
        fill: var(--color-primary);
    }

    .connectionSyncInfo {
        padding: 10px;
        background-color: white;
        width: fit-content;
        min-width: 150px;
        max-width: 600px;

        display: flex;
        flex-direction: column;

        box-shadow: var(--box-shadow);

        h3 {
            color: var(--color-primary);
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                padding: 0;
                margin: 0;
            }
        }

        .rowConnName {
            max-width: 100%;
        }

        .connectionSyncInfo_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
        }
    }

    .toggleSingleSyncView {
        user-select: none;
        cursor: pointer;
    }
}
