.visibility-icon-image {
    --see-through: 0.5;
    opacity: var(--see-through);
    transition: opacity 0.5s ease-in;
}

.visibility-icon-image:hover {
    opacity: 1;
    /* ease in */
    transition: opacity 0.5s ease-in;
}
