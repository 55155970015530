.addEditCommitted-drawer {
    padding: 20px 40px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* padding: 180px 100px; */
}

.addEditCommitted-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* font-feature-settings: 'liga' off; */
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 10px;
}

.addEditCommitted-drawer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.addEditCommitted-drawer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.addEditCommitted-cancel {
    margin-bottom: 10px !important;
}

.addEditCommitted-cancel span {
    width: 100px;
}
