.clientListTable-status {
    /* left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; */
}

.clientListTable_settingsBtn:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}
