.notifyDrawer-drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 200px;

    --notif-details-width: 380px;
}

.notifyDrawer-drawer h1 {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-primary);
    margin-bottom: 30px;
}

.notifyDrawer-drawer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.notifyDrawer-drawer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.notifyDrawer-cancel {
    margin-bottom: 10px !important;
}

.notifyDrawer-cancel span {
    width: 100px;
}

.notifyDrawer-details-header {
    font-size: 16px;
    color: var(--color-dark-gray);
    margin-bottom: 15px;
    font-weight: bold;
    margin-left: 20px;
}

.notifyDrawer-details {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: var(--notif-details-width);
}

.notifyDrawer-details div {
    margin-bottom: 10px;
    margin-left: 20px;
}

/* .notifyDrawer-notifMethod {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: var(--notif-details-width);
} */

.notifyDrawer-checkboxDiv {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}

.notifyDrawer-checkboxDiv > div {
    margin-right: 10px;
}

.notifyDrawer-prev {
    position: relative;
    background-color: white;
    width: var(--notif-details-width);
    margin-bottom: 15px;
    border-top: 4px solid var(--color-primary);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* padding-left: 30px; */
    align-items: center;
}

.notifyDrawer-prev-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    width: 100%;
    cursor: pointer;
}

.notifyDrawer-prev-header-bell {
    position: absolute;
    left: 16px;
}

.notifyDrawer-prev-header-text {
    margin-left: 50px;
}

.notifyDrawer-prev-header-arrow {
    position: absolute;
    right: 16px;
    /* width: 14px;
    height: 10px;
    aspect-ratio: 1; */
}

.notifyDrawer-prev-notif {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 52px;
}

.notifyDrawer-prev-notif div:nth-child(1) {
    font-weight: 600;
    margin-left: 20px;
}

.notifyDrawer-prev-notif div:nth-child(2) {
    position: absolute;
    margin-left: 130px;
}
