$iconMarginLeft: 28px;

.x {
    // margin-left: $iconMarginLeft;
    path {
        fill: red;
    }
}

.envelope {
    // margin-left: $iconMarginLeft;
    path {
        fill: orange;
    }
}

.download {
    // margin-left: $iconMarginLeft;
    path {
        fill: green;
    }

    &:hover {
        transform: scale(1.2);
        transition: 0.3s;
    }
}
