.page {
    background-color: #fff;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
    height: fit-content;
    margin-bottom: 20px;
    margin-top: 20px;
}

.page,
.pageImage {
    position: relative;
}

.pageImage {
    -webkit-user-drag: none;
    height: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 100%;
}
