.main {
    position: relative;
    display: flex;
    align-items: center;
}

.text {
    position: absolute;
    // background-color: #000;
    max-width: 200px;
    min-width: fit-content;
    white-space: nowrap;
    // padding: 10px;
    border-radius: 5px;
    border: 1px solid #000;
    // color: #fff;
    background-color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    z-index: 100000000;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
