.docDrawer-container {
    --docDrawer-contentPadding: 20px;
    overflow: auto;
    height: calc(100vh - 80px);
    width: 100%;
    background-color: var(--color-accent);
}

.docDrawer-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: var(--color-primary);
}

.docDrawer-content {
    position: relative;
    padding-top: var(--docDrawer-contentPadding);
    padding-left: var(--docDrawer-contentPadding);
    padding-right: var(--docDrawer-contentPadding);
}

.docDrawer-button {
    padding: 20px;
    min-width: 120px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.docDrawer-section {
    margin-top: 15px;
    margin-bottom: 15px;
}

.docDrawer-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.docDrawer-topControls path {
    fill: var(--color-dark-gray);
}

.docDrawer-topControls {
    position: absolute;
    top: 23px;
    right: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 1;
}

.docDrawer-topControls-reload {
    margin-right: 20px;
    padding: 2px;
}

.docDrawer-topControls-reload:hover {
    cursor: pointer;
}

.docDrawer-topControls-undo {
    margin-right: 20px;
    padding: 2px;
}

.docDrawer-topControls-undo:hover {
    cursor: pointer;
}

.docDrawer-topControls-exit {
    padding: 2px;
}

.docDrawer-topControls-exit:hover {
    cursor: pointer;
}

.docDrawer-label {
    font-weight: bold;
    margin-right: 5px;
    white-space: nowrap;
    display: inline-flex;
}

.docDrawer-value {
    white-space: nowrap;
    display: inline-flex;
}

.docDrawer-toggleDiv {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.docDrawer-uploadDocMessage {
    margin: 90px;
    font-weight: bold;
}
