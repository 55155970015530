.advCapCalls_dueMetContainer {
    display: inline-block;
    margin-left: 20px;
    font-size: 18px !important;
    color: var(--color-text-light);
    font-weight: 500;
}

.advCapCalls_dueMetItem {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.advCapCalls_dueMetItem:hover {
    color: var(--color-link-hover);
}

.advCapCalls_dueMetSelected {
    display: inline;
    text-decoration: none;
    color: var(--color-link);
    font-weight: 600;
}

.advCapCalls_updateButton {
    float: left;
    width: 140px;
    margin-top: -165px;
    margin-left: 60px;
}
