.docDetails-component {
    position: relative;
}

.docDetails-content {
    padding: 0 5px;
}

/* .docDetails-label {
    font-weight: bold;
    margin-right: 5px;
    white-space: nowrap;
    display: inline-flex;
} */

/* .docDetails-value {
    white-space: nowrap;
    display: inline-flex;
} */

.docDetails-row {
    display: flex;
    align-items: center;
    line-height: 30px;
    justify-content: space-between;
}

.docDetails-hashWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.docDetails-hashWrapper path {
    fill: var(--color-primary);
}

.docDetails-title {
    margin-bottom: 20px !important;
}
