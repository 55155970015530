.cashFlowComponent {
    display: flex;
    flex-direction: column;
}

.cashFlowHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cashFlowTitle {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-primary);
    margin-left: 20px;
}

.cashFlowLegend {
    margin-right: 16px;
    margin-top: 5px;
    line-height: 16px;
    float: right;
    display: flex;
    flex-direction: row;
}

.cashFlowLegendItem {
    display: flex;
    flex-direction: row;
}

.cashFlowLegendCircle {
    --diameter: 13px;

    width: var(--diameter);
    height: var(--diameter);
    border-radius: 50%;
    margin-right: 7px;
    position: relative;
    margin-left: 20px;
    margin-top: 2px;
}

.cashFlowLegendText {
    font-size: 18px;
    font-weight: normal;
}

.cashFlowGraph {
    padding-top: 20px;
}

@media (max-width: 480px) {
    .cashFlowComponent {
        margin-bottom: 0px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .cashFlowComponent {
        margin-bottom: 0px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .cashFlowComponent {
        margin-bottom: 120px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .cashFlowComponent {
        margin-bottom: 0px;
    }
}
