.enableExteg-drawer {
    padding: 100px 50px;
    /* display: flex;
    flex-direction: column; */
}

.enableExteg-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* font-feature-settings: 'liga' off; */
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 10px;
}

.enableExteg-drawer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.enableExteg-drawer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.enableExteg-cancel {
    margin-bottom: 10px !important;
}

.enableExteg-cancel span {
    width: 100px;
}

.enableExteg-delete {
    margin-bottom: 10px !important;
}

.enableExteg-delete span {
    width: 100px;
    color: var(--color-dark-red);
}

.enableExteg-creds {
    display: flex;
    flex-direction: column;
    align-items: center;
}
