.tax-center-container {
    --margin-horiz: 60px;

    margin-top: 20px;
}

.tax-center-cpa-button {
    position: relative;
    height: 35px;
    width: calc(100% - var(--margin-horiz) * 2);
    margin-left: var(--margin-horiz);
    /* margin-top: 20px;
    margin-bottom: 20px; */
}

.tax-center-cpa-button button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.tax-center-widgets {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 120px);
    margin-left: var(--margin-horiz);
    margin-top: 20px;
}

.tax-center-widgets .dc-container {
    width: calc(100% - 40px) !important;
    margin: 0;
    margin-right: 40px !important;
}

.tax-center-widgets .distributions-container {
    width: calc(100% - var(--margin-horiz)) !important;
    margin: 0;
    margin-left: 20px;
}

.tax-center-widgets .emptyOverlay_component .emptyOverlay_overlay {
    width: 100%;
    left: 0;
}

.tax-center-downloadBtn {
    float: right;
    margin: 10px var(--margin-horiz);
    margin-top: -100px;
    width: 140px;
}

@media (max-width: 768px) {
    .tax-center-cpa-button {
        width: calc(100% - 60px);
    }

    .tax-center-downloadBtn {
        width: 150px;
        margin: 10px 20px;
    }

    .tax-center-container {
        padding: 20px;
    }

    .tax-center-widgets {
        display: block;
        width: calc(100%);
        margin-left: 0px;
    }

    .tax-center-widgets .widget {
        width: calc(100% - 40px) !important;
    }

    .tax-center-widgets .distributions-container {
        margin-left: 0px;
        margin-top: 20px;
    }

    .dc-info {
        width: 100%;
    }
}

.mobile-width-constraint {
    max-width: 75vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* @media (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 600px) {
}

@media (min-width: 601px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 1024px) {
} */
