.dc-container {
    position: relative;
    width: calc(100% - 80px);
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 3px;
}

.dc-chart-container {
    /* margin-top: 20px; */
}

.dc-slim-header {
    display: flex;
    margin-bottom: 10px;
}

.dc-slim-header h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-primary);
}

.dc-slim-header .a {
    margin-left: auto;
}

.dc-header {
    display: flex;
    justify-content: left;
}

.dc-header h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-primary);
}

.dc-slim-header-container .dc-header h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-dark-gray);
}

.gathering-documents {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: -10px;
}

.gathering-documents span {
    margin-right: 10px;
}

.dc-slim-header-container .gathering-documents {
    margin-top: -10px;
}

.dc-header img {
    height: 25px;
    margin-right: 15px;
}

.dc-header span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: var(--color-dark-gray);
    margin-left: auto;
}

.dc-slim-header-container .dc-header span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: var(--color-dark-gray);
}

.dc-bar-container {
    position: relative;
    margin-top: 33px;
    height: 15px;
}

.dc-bar-container-small {
    margin-top: 3px;
}

.dc-bar {
    position: absolute;
    background: var(--color-lighter-gray);
    height: 15px;
    width: calc(100%);
}

.dc-bar-filled {
    position: absolute;
    background: var(--color-teal);
    height: 15px;
}

.dc-info {
    margin-top: 27px;
}

.dc-info-small {
    margin-top: 10px;
}

.dc-info div {
    display: flex;
    margin-bottom: 20px;
}

.dc-info div label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-dark-gray);
    margin-right: 10px;
}

.dc-info div span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-dark-gray);
    margin-left: auto;
}

@media (max-width: 480px) {
    .dc-header h1 {
        font-size: 22px;
    }
}
