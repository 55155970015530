.emptyOverlay_component {
    --pad: 20px;

    position: relative;
    height: 100%;
    width: 100%;
}

.emptyOverlay_overlay {
    /* background-color = var(--color-primary) as rgb */
    background-color: rgba(42, 66, 102, 0.85);
    top: 0;
    left: var(--pad);
    width: calc(100% - var(--pad) * 2);
    height: 100%;
    position: absolute;
    z-index: 4000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.emptyOverlay_text {
    padding: 40px;
    font-size: 18px !important;
    font-weight: 600;
    line-height: 26px;
    color: white;
}

@media (max-width: 480px) {
    .emptyOverlay_component {
        height: auto;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .emptyOverlay_component {
        height: auto;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .emptyOverlay_component {
        height: auto;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .emptyOverlay_component {
        height: auto;
    }
}
