.show-tooltip {
    width: 21px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 12px;
    background-color: #edc30c;
    color: #fff;
    cursor: pointer;
}

.show-tooltip:hover {
    background-color: #ffe573;
}

.has-tooltip {
    display: inline-block;
    text-decoration: underline;
    position: relative;
}

.has-tooltip .tooltip-top {
    display: none;
    font-size: 14px;
    transform: translate(-50%, -100%);
    top: -20px;
    left: 50%;
    padding: 10px 20px;
    min-width: 300px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    /* z-index:99999999; */
    /* margin-bottom: 0; */
}
.has-tooltip:hover .tooltip-top {
    display: block;
}

.has-tooltip .tooltip-top i {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
}

.has-tooltip .tooltip-top i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
