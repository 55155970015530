$colorLink: #02b6b0;

.mainBackground {
    position: fixed;
    top: 0;
    left: 320px;
    width: calc(100% - 320px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
        border-radius: 20px;
        position: relative;
        background-color: white;
        padding: 60px;
        height: 80%;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: 16px;
            margin: 0px;
        }

        .title {
            color: var(--color-primary);
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .investmentListHeader {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;

            .investmentListTitle {
                font-size: 20px;
                font-weight: bold;
                margin-right: 20px;
            }
        }

        .investmentList {
            border-radius: 10px;
            border: 1px solid #ddd;
            box-shadow: var(--box-shadow);
            background-color: #f8f8f8;
            overflow: auto;
            width: '80%';
            border: '1px solid #ddd';
            // max-height: 20%;

            .investmentChoice {
                display: flex;
                align-items: center;
                padding: 5px 20px 5px 20px;
                cursor: pointer;
                &:hover {
                    background-color: #ccc;
                }
            }

            .selected {
                background-color: $colorLink;
                color: white;
                &:hover {
                    background-color: darken($colorLink, 3%);
                }
            }
        }
    }
}
