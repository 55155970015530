.drawerInputObject2-datePicker {
}

.drawerInputObject2-datePicker .react-datepicker__input-container input,
.drawerInputObject2-datePicker .react-datepicker__input-container {
    height: var(--drawer-obj-height) !important;
    box-shadow: var(--box-shadow);
    width: 100% !important;
    margin: 0 !important;
    border: none;
}

input {
    margin: 0 !important;
    /* height: var(--drawer-obj-height) !important; */
    box-shadow: var(--box-shadow);
    width: 100% !important;
}

input::placeholder {
    color: var(--drawer-input-placeholder-color) !important;
    opacity: 1;
    font-weight: 500;
}

#textInput {
    font-size: 15px !important;
}

#textInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--drawer-input-placeholder-color) !important;
    opacity: 1;
    font-weight: 500;
}

#textInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--drawer-input-placeholder-color) !important;
    opacity: 1;
    font-weight: 500;
}

#textInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--drawer-input-placeholder-color) !important;
    opacity: 1;
    font-weight: 500;
}
