.main {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 400px;

        h1 {
            margin: 0;
            font-size: 1.3rem;
        }
    }

    .progressBar {
        height: 20px;
        border: 1px solid #000;
        width: 100%;
        display: flex;
        align-items: center;

        .progressItem {
            height: 100%;
        }
    }

    .legend {
        display: flex;
        align-items: center;
        gap: 10px;

        .legendItem {
            white-space: nowrap;
        }
    }

    .popper {
        position: absolute;
        white-space: nowrap;
        background-color: var(--color-link);
        color: white;
        font-size: 1rem;
        // background-color: white;
        box-shadow: var(--box-shadow);
        padding: 10px;
    }
}
