$iconMarginLeft: 28px;

.main {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: min-content;

    &:not(.disabled):not(.noHover) {
        &:hover {
            .icon {
                path {
                    transform: scale(1.1) translateX(-5%) translateY(-5%);
                    filter: brightness(130%);
                }
            }
        }
    }

    &:not(.disabled) {
        .label {
            color: var(--icon-button-component-color);
        }
    }

    .icon {
        path {
            fill: var(--icon-button-component-color);
        }
    }

    .label {
        font-size: 14px;
    }

    .disabled {
        path {
            fill: var(--color-light-gray);
        }
    }

    .text {
        background-color: red;
        z-index: 10000000;
    }
}
