.tfa-drawer {
    background-color: var(--color-title);
    width: auto;
    position: relative;
    min-height: 100%;
    padding-bottom: 60px;
}

.tfa-drawer label {
    font-weight: bold;
}

.section-divider {
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
}

.tfa-setup {
    display: flex;
    /* Enable flexbox */
    justify-content: center;
    /* Horizontally center children */
    align-items: center;
    /* Vertically center children */
    /* Take full height of parent or a specific height if needed */
    min-height: 200px;
}

.tfa-loading {
    font-size: 1.5em;
    /* Increase font size */
    color: #555;
    /* A muted text color, but it's optional */
    padding: 20px;
    /* Some padding for better appearance */
    border-radius: 5px;
    /* Optional rounded corners */
    background: #f5f5f5;
    /* A light background to make it pop a little */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* A subtle shadow for depth */
}

.tfa-setup-content,
.tfa-setup-details {
    padding: 30px;
    border-radius: 5px;
    background-color: var(--color-title);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 80px;
}

.tfa-header {
    font-weight: bold;
    margin-bottom: 30px;
}

.tfa-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    /* margin-bottom: 40px; */
}

.label {
    margin-bottom: 10px;
    /* Standardize margin below all labels */
}

.tfa-disable-button {
    background-color: var(--color-link);
    color: var(--color-title);
}

.tfa-verify-button {
    background-color: var(--color-link);
    color: var(--color-title);
}

.tfa-qrcode {
    margin: 20px 0 20px;
    position: inherit;
    /* Added more margin at the bottom to separate from the next section */
}

.tfa-input {
    padding: 10px 15px;
    /* Increased padding slightly */
    margin-top: 15px;
    margin-bottom: 8px;
    /* Increased margin for better spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: fit-content;
}

.tfa-cancel {
    position: inherit;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.tfa-cancel-text {
    text-decoration: underline;
}

.otp-container {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
    background-color: #f5f5f5;
    overflow: scroll;
    cursor: pointer;
    /* To indicate clickable/copyable */
    position: relative;
    max-width: 95%;
}

.otp-url {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.otp-container,
.recovery-codes-container {
    margin-bottom: 20px;
    /* New margin added to separate from next section */
}

.recovery-codes-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    float: left;
}

.recovery-codes-container:first-of-type::after {
    content: '';
    display: block;
    clear: both;
    /* Clear float to ensure following elements start after this element */
}
