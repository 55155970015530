.allocTblComponent {
    font-size: 18px;
    width: calc(50% - 100px);
    margin-left: 60px;
    float: left;
}

.allocTbl_header {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-primary);
}

.allocTblComponent table {
    width: 100%;
}

.allocTblComponent th {
    font-weight: 600;
    height: 40px;
}

.allocTblComponent tr td {
    text-align: center;
    height: 32px;
}

.allocTblComponent tr td:first-child {
    text-align: left;
    font-weight: 600;
}

@media (max-width: 480px) {
    .allocTblComponent {
        margin-left: 20px;
        width: calc(100% - 80px);
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .allocTblComponent {
        margin-left: 20px;
        width: calc(100% - 80px);
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .allocTblComponent {
        margin-left: 20px;
        width: calc(100% - 80px);
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .allocTblComponent {
        margin-left: 20px;
        width: calc(100% - 80px);
    }
}
