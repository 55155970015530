.docContainer {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    /* position: relative; */
    width: 100%;
    scroll-behavior: smooth;

    /* Add the following styles to enable scrolling */
    overflow: auto;
    /* This allows vertical scrolling when content overflows */
    max-height: calc(100vh - 80px);
    /* Set a maximum height to limit the container's height */
}

.pageContainer {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    width: fit-content;
    align-items: center;
}
