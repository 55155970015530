.dragDropComponent {
    user-select: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    height: calc(67px + 16px * 2);
}

.dragDropComponent > div {
    left: 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dragDropComponent-unset div,
.dragDropComponent-error div {
    /* background-color: var(--color-dark-gray); */
    font-size: 12px;
}

.dragDropComponent-unset div:nth-child(1),
.dragDropComponent-error div:nth-child(1) {
    font-size: 16px;
    color: var(--color-darkGray);
    color: var(--color-lightGray);
}

.dragDropComponent-error div:nth-child(1) {
    color: var(--color-dark-red) !important;
}

.dragDropComponent-unset div:nth-child(2),
.dragDropComponent-error div:nth-child(2) {
    margin-top: 8px;
    margin-bottom: 8px;
}

.dragDropComponent-unset div:nth-child(3),
.dragDropComponent-error div:nth-child(3) {
    cursor: pointer;
}

.dragDropComponent-dragging {
    border: 1px dashed black;
    background-color: var(--color-lighter-gray);
}

.dragDropComponent-filetypes {
    float: left;
    font-size: 12px;
    margin-top: 7px;
    color: var(--color-darkGray);
}

.dragDropComponent-success {
    display: flex;
    flex-direction: row !important;
    font-size: 16px;
    color: var(--color-darkGray);
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;
}

.dragDropComponent-success span {
    margin: 25px;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dragDropComponent-success-cancel {
    cursor: pointer;
}

.dragDropComponent-success-cancel:hover {
    transform: scale(1.05);
}

.dragDropComponent-processing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes processingBar {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.dragDropComponent-processingBar {
    overflow: hidden;
    border-radius: 3px;
    margin-top: 20px;
    width: 260px;
    height: 5px;
    background-color: var(--color-lighter-gray);
}

.dragDropComponent-processingBar div {
    height: 100%;
    background-color: var(--color-teal);
    animation: processingBar var(--processing-time) ease-in-out;
}

.basicFieComponentlds-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
