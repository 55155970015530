.progressComponent-container {
    --progress-step-width: 30px;
    position: relative;
    /* position: absolute; */
    /* margin:auto; */
    /* margin-top: 40px; */
    width: 300px;
    display: flex;
    /* top: 0; */
}

.progressComponent-step {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: var(--color-lighter-gray);

    border: 1px solid var(--color-lighter-gray);
    border-radius: 40px;
    font-size: 16px;
    height: var(--progress-step-width);
    aspect-ratio: 1/1;
}
.progressComponent-step span {
    color: #969696;
    border: solid 1px transparent !important;
}
.progressComponent-progress-error {
    background: #ffffff !important;
    border: solid 1px #b64040 !important;
    font-weight: 600;
}
.progressComponent-progress-error span {
    color: #b64040 !important;
}
.progressComponent-waiting {
    color: #fff !important;
    background: #969696 !important;
    border: solid 1px #969696 !important;
    font-weight: 600;
}
.progressComponent-waiting span {
    color: #fff !important;
    border: solid 1px transparent !important;
}
.progressComponent-active {
    background-color: var(--color-primary) !important;
    border: solid 1px var(--color-primary) !important;
}
.progressComponent-active span {
    color: white;
    background-color: var(--color-primary) !important;
    border: solid 1px var(--color-primary) !important;
}
.progressComponent-complete {
    border: solid 1px var(--color-teal) !important;
    color: var(--color-teal) !important;
}
.progressComponent-complete span {
    color: #009f98;
}
.progressComponent-line {
    position: relative;
    border-bottom: solid 1px var(--color-lighter-gray);
    width: calc(var(--progress-step-width) * 1) !important;
    height: calc(var(--progress-step-width) / 2);
}
