.add-investment-container {
    width: 100%;
    height: 100%;
    z-index: 0;
    background:
        url('../images/background-transparent.png') no-repeat fixed,
        url('../images/add-investment-image.png') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.altx-link-logo {
    text-align: center;
    margin-top: 50px;
}

.stage-container h1 {
    margin: 20px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-primary);
}

.stage-container h2 {
    margin-top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-primary);
}

.stage-container p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--color-dark-gray);
    margin-bottom: 40px;
}

.stage-container .bold {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: var(--color-dark-gray);
    margin-bottom: 10px;
}

.search-investments-container {
}

.search-investments-container .results-container {
}

.search-investments-container .no-results {
    width: calc(50% - 21px);
}

.no-results-div {
    background: #fff;
    width: 100%;
    border: solid 1px #ddd;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 30px 20px 0px 20px;
}

.no-results-div p {
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.no-results-div .a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    text-decoration-line: underline;
    font-feature-settings: 'liga' off;
    color: var(--color-link);
}

.no-results-div .a:hover {
    cursor: pointer;
    color: #444 !important;
}

.box p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: var(--color-dark-gray);
}

.two-factor-conainer p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
}

.signing-container {
    position: relative;
    text-align: center;
    opacity: 1;
    height: 1000px;
    width: 100%;
    margin: auto;
    background: rgba(42, 66, 102, 0.55);
    border-radius: 3px;
}

.signing-box {
    position: absolute;
    top: 15%;
    width: 50% !important;
}

.two-factor-conainer .a {
    margin: 10px 0 30px 0;
}

.two-factor-conainer h2 {
    margin-bottom: 40px;
}

.two-factor-conainer ol {
    padding: 20px 60px;
}

.two-factor-conainer li {
    text-align: left;
}

.new-investment-container {
    max-width: 35% !important;
    padding: 40px !important;
}

.new-investment-container label {
    width: 400px;
    text-align: left;
}

.new-investment-container select {
    width: 100%;
}

.new-investment-container .select-new-portal {
    line-height: 35px;
    padding: 8px 10px;
    background-image: url('/public/images/icons/plus.png');
    background-size: 16px;
    background-position: 12px;
    background-repeat: no-repeat;
}

.new-investment-container .select-new-portal span {
    padding-left: 36px;
    width: 100%;
}

.new-investment-container .select-new-portal:hover {
    background-color: var(--color-hover);
}

.results-container {
    margin-top: 20px;
}

.results-container label {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.results-container .results {
    text-align: left;
    padding: 10px;
    max-height: 150px;
    overflow-y: scroll;
    background-color: #fbfbfb;
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 2px 4px 0 rgba(181, 181, 181, 0.2);
}

.results-container .results ::-webkit-scrollbar {
    width: 20px;
}

.results-container .results ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.results-container .results ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

.results-container .results .a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-decoration-line: underline;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.connecting-gif {
    position: relative;
    margin: auto;
    background-image: url('/public/images/loading.gif');
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
    text-align: center;
}

.error-icon {
    position: relative;
    margin: auto;
    background-image: url('/public/images/connection-error.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150px;
    height: 200px;
    width: 200px;
    text-align: center;
}

.timedout {
    position: relative;
    margin: auto;
    background-image: url('/public/images/timeout-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150px;
    height: 200px;
    width: 200px;
    text-align: center;
}

.new-integration-container .inputs {
    text-align: left;
    width: 80%;
    margin-left: 10%;
}

.new-integration-done {
    height: 200px;
    background-image: url('/public/images/connection-pending.png') !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 150px;
}

.credentials-container .popper {
    top: 25%;
    left: 51%;
}

.signing-confirmation-box {
    position: absolute;
    z-index: 1;
    background-color: rgba(42, 66, 102, 0.55);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

.credentials-container .inputs .checkbox-right-label,
.new-integration-container .inputs .checkbox-right-label {
    margin-top: 20px;
}

.credentials-container .inputs .checkbox-right-label label,
.new-integration-container .inputs .checkbox-right-label label {
    position: absolute;
    margin-left: 30px;
    margin-top: -3px;
    width: 60%;
}

.tfa-box {
    width: 30% !important;
}

.tfa-box h3 {
    margin-bottom: 25px;
}

.tfa-box .a {
    margin-top: 20px;
}

.connecting-box label {
    margin-top: 40px;
}

.gray-box {
    margin-top: 20px;
    background: var(--color-light-separator);
    padding: 20px 40px;
}

.gray-box h3 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-dark-gray);
}

.gray-box span {
    display: block;
    margin-top: 20px !important;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-dark-gray);
}

.gray-box .a {
    margin-top: 20px;
}

.add-new-portal {
    color: var(--color-link) !important;
}

.add-new-portal:hover {
    color: var(--color-link-hover) !important;
}

@media (max-width: 480px) {
    .altx-link-logo {
        margin-top: 10px;
    }

    .stage-container {
        margin-top: 100px;
    }

    .stage {
        height: 820px;
    }

    .connecting-stage {
        height: 660px;
    }

    .credentials-container {
        padding: 10px;
        width: calc(100% - 30px);
    }

    .credentials-container .popper {
        left: 0;
        right: 0;
    }

    .credentials-container .inputs {
        width: calc(100% - 30px);
        margin: auto;
    }

    .checkbox-right-label label .show-tooltip {
        margin-right: 0 !important;
        float: right;
    }

    .credentials-container .inputs .checkbox-right-label label {
        width: 77.5%;
    }

    .no-results-div {
        padding: 0;
        padding-top: 20px;
    }

    .new-investment-container {
        margin: 0 !important;
        width: calc(100% - 80px) !important;
        max-width: 100% !important;
    }

    .new-investment-container .autocomplete-container {
        margin: 0;
        width: calc(100% - 20px);
    }

    .new-investment-container .autocomplete-container .autocomplete-list {
        width: calc(100% - 80px) !important;
    }

    .signing-confirmation-box {
        height: 800px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .altx-link-logo {
        margin-top: 10px;
    }

    .no-results-div {
        padding: 0;
        padding-top: 20px;
    }

    .new-investment-container {
        margin: 0 !important;
        width: calc(100% - 80px) !important;
        max-width: 100% !important;
    }

    .new-investment-container .autocomplete-container {
        margin: 0;
        width: calc(100% - 20px);
    }

    .new-investment-container .autocomplete-container .autocomplete-list {
        width: calc(100% - 80px) !important;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .altx-link-logo {
        margin-top: 10px;
    }

    .no-results-div {
        padding: 0;
        padding-top: 20px;
    }

    .new-investment-container {
        margin: 0 !important;
        width: calc(100% - 80px) !important;
        max-width: 100% !important;
    }

    .new-investment-container .autocomplete-container {
        margin: 0;
        width: calc(100% - 20px);
    }

    .new-investment-container .autocomplete-container .autocomplete-list {
        width: calc(100% - 80px) !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .altx-link-logo {
        margin-top: 10px;
    }

    .no-results-div {
        padding: 0;
        padding-top: 20px;
    }

    .new-investment-container {
        margin: 0 !important;
        width: calc(100% - 80px) !important;
        max-width: 100% !important;
    }

    .new-investment-container .autocomplete-container {
        margin: 0;
        width: calc(100% - 20px);
    }

    .new-investment-container .autocomplete-container .autocomplete-list {
        width: calc(100% - 80px) !important;
    }
}
