.statusCircleContainer {
    position: relative;
    height: var(--statusCircle-size);
    aspect-ratio: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: calc(var(--statusCircle-fontSize) * 1px);
}

.statusCircleContainer::before {
    --c: var(--statusCircle-filledColor);
    --cEmpty: var(--statusCircle-emptyColor);
    --p: var(--statusCircle-percent);
    --t: var(--statusCircle-thickness);
    --tCalc: calc((100 - var(--t)) * 1%);
    --pCalc: calc(var(--p) * 1%);

    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;

    background: conic-gradient(var(--c) 0% var(--pCalc), var(--cEmpty) var(--pCalc));
    -webkit-mask: radial-gradient(farthest-side, #0000 0% var(--tCalc), #000 var(--tCalc) 100%);
    mask: radial-gradient(farthest-side, #0000 0% var(--tCalc), #000 var(--tCalc) 100%);
}

.statusCircleContainerHover:hover {
    background-color: #0000;
    cursor: pointer;
    background-image: url('/public/images/icons/closeGray.png');
    background-position: center;
    background-size: cover;
}

.statusCircleContainerHover:hover::before {
    background: none;
}
