.connections-container .pagTable_headerText {
    font-size: 16px !important;
    width: 100%;
}

.connections-container .pagTable_headerText h1 {
    margin-bottom: 10px;
}

.connections-container input {
    width: 30%;
}

.connections-container div h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-dark-gray);
}

.connections-container .connections-header .dropdown-container {
    width: 20%;
    float: right;
}

.connection-drawer {
    padding: 0 75px 20px 75px;
}

.connection-drawer h1 {
    margin-bottom: 10px !important;
    text-align: left;
}

.connection-drawer .input-group {
    left: 0;
    margin-top: 0;
}

.connection-drawer .input-group label {
    margin-bottom: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.connection-drawer .input-group .inline {
    float: right;
}

.connection-drawer .input-group input {
    height: 18px !important;
}

.connection-drawer .input-group input:not(input[type='checkbox']) {
    width: calc(100% - 22px) !important;
}

.connection-drawer .input-group span {
    margin-bottom: 40px !important;
}

.connection-drawer .input-group span .error-visible {
    font-size: 14px !important;
    margin-bottom: 40px !important;
}

.connection-drawer .input-group .dropdown-container {
    width: 100% !important;
    margin-top: 6px;
}

.connection-drawer .input-group input[type='checkbox'] {
    display: inline-block;
    width: 30px;
}

.connection-drawer .input-group .request-creds-label {
    position: absolute;
    margin-left: 5%;
    margin-top: -25px;
}

.status-header {
    width: 100%;
    text-align: left;
}

.status-header .a,
.status-header .a-disabled {
    float: right;
}

.status {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

.green {
    color: var(--color-teal);
}

.yellow {
    color: #edc30c;
}

.red {
    color: #b64040;
}

.label-with-cb {
    width: 100%;
}

.label-with-cb label,
.label-with-cb div,
.label-with-cb input {
    display: inline-block;
}

.label-with-cb div {
    position: relative;
    float: right;
}

.label-with-cb input {
    position: absolute;
    top: 12px;
    right: 85px;
}

.label-with-cb div label {
    position: static;
    margin-left: 120px;
}

.request-new-creds {
    text-align: left;
    margin-top: 15px;
    margin-left: -5px;
}

.new-creds-requested {
    margin-left: 5px !important;
}

.connection-row td .arrow-up {
    position: relative !important;
    left: 0 !important;
    top: 0;
    border-left: none;
    margin-right: 10px;
}

.expanded-row td div .checkbox {
    margin-left: 40px;
}
