.pagFooter_footRange {
    margin-left: 30px;
}

.pagFooter_footContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    width: fit-content;
}

.pagFooter_footPageBack {
    margin-left: 15px;
    margin-right: 15px;
    /* height: 100%; */
    display: flex;
    align-items: center;
}

.pagFooter_link {
    cursor: pointer;
}

/* .pagFooter_link:hover {
    transform: scale(1.05);
} */

.pagFooter_footPageDivider {
    margin-left: 10px;
    margin-right: 10px;
}

.pagFooter_footPageForward {
    margin-left: 15px;
    margin-right: 15px;
    /* height: 100%; */
    display: flex;
    align-items: center;
}

.pagFooter_footPageNumber {
    /* width: 30px; */
}

.pagFooter_footPageNumber > input {
    height: 20px;
}

@media (max-width: 480px) {
    .pagFooter_footRange {
        margin-left: 10px;
    }

    .pagFooter_footContainer {
        justify-content: center;
        width: 100%;
    }

    .pagFooter_footPageBack {
        margin-left: 10px;
    }
}
