.mngConn_component {
    padding: 40px 60px;
    --triangle-size: 10px;
    --arrow-color: #edc30c;
    --color-light-gray: #cbcbcb;
    --table-header-color: var(--color-accent);
    --row-border-color: #adadad;
    --investment-row-color: #f1f5fb;
}

.mngConn_header {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-primary);
    margin-bottom: 30px;
    margin-top: 20px;
}

.mngConn_searchHeader {
    font-size: 16px;
    font-weight: normal;
    color: var(--color-dark-gray);
}

.mngConn_searchContainer {
    --search-height: 39px;
    position: relative;
    margin-top: 7px;
    /* background-color: red; */
    width: 370px;
    height: var(--search-height);
    margin-bottom: 30px;
    /* border: 1px solid red; */
}

.mngConn_searchContainer_input {
    position: absolute;
    width: 100%;
    display: flex;
}

.mngConn_searchContainer_input input {
    position: absolute;
    padding: 0;
    padding-top: -10px;
    margin: 0 !important;
    border: none !important;
    background-color: red;
}

.mngConn_searchContainer_inputVector {
    --arrow-circle-height: 27px;
    --arrow-circle-border-width: 1px;

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    right: 10px;
    height: var(--arrow-circle-height);

    top: calc((var(--search-height) - var(--arrow-circle-height) - var(--arrow-circle-border-width) * 2) / 2);
    border-radius: 50%;
    aspect-ratio: 1;
    border: var(--arrow-circle-border-width) solid var(--color-light-gray);
}

.mngConn_searchContainer_inputVector img {
    height: 13px;
    width: 13px;
}

/* row */
.mngConn_row_remove {
    color: var(--color-dark-red);
}

.mngConn_row_manage {
    color: var(--color-dark-gray);
}

.mngConn_row_sync {
    color: var(--color-dark-gray);
}

.mngConn_link {
    text-decoration: underline;
    cursor: pointer;
}

/* table */
.mngConn_tableContainer table {
    width: 100%;
    border-spacing: 0;
}

#mngConn_tabContainer {
    margin-bottom: 10px;
    margin-top: 10px;
}

.mngConn_tableHeader {
    font-weight: bold;
    /* color: var(--color-primary); */
    background-color: var(--table-header-color);
}

/* all table rows */
.mngConn_tableContainer tr {
    height: 61px;
}

.mngConn_tableContainer th {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

.mngConn_tableRow {
    font-size: 16px;
    border: 1px solid var(--row-border-color);
}

.mngConn_mobile_tableRow {
    padding: 10px;
    margin: 1px -20px;
}

.altx-mobile-table .mngConn_table_investmentRow {
    padding: 10px;
    border-bottom: solid 1px #ccc;
}

.altx-mobile-table .mngConn_table_investmentRow .admin-review-desc span {
    margin-top: 0px !important;
}

.altx-mobile-table-row .mngConn_dropdown,
.altx-mobile-syncing-row .mngConn_dropdown {
    padding-left: 0px;
    margin-top: 20px;
}

.altx-mobile-table-row .mngConn_dropdown img {
    margin-right: 5px;
}

.inline-links {
    margin-top: 20px;
}

.inline-links .mngConn_link {
    display: inline;
    margin-right: 50px;
}

.mngConn_tableRow td:nth-child(1) {
    /* padding-right: 10px; */
    width: calc(20px + var(--triangle-size));
}

.mngConn_tableRow td:nth-child(2) {
    font-weight: 600;
}

.mngConn_tableRow_statusBoxError {
    --box-pad: 10px;
    display: flex;
    align-items: center;

    width: fit-content;
    height: 32px;
    cursor: pointer;

    padding-left: var(--box-pad);
    padding-right: var(--box-pad);

    margin-left: calc(-1 * var(--box-pad));
    border-radius: 3px;
    border: 1px solid var(--color-dark-red);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.mngConn_tableRow_statusBoxError:hover {
    background-color: #cf4747 !important;
}

.mngConn_table_investmentRow {
    font-size: 18px;
    color: var(--color-dark-gray);
    background-color: var(--color-very-light-gray);
    border-top: 3px solid var(--color-background);
    border-bottom: 3px solid var(--color-background);
}

.mngConn_table_investmentRow td:nth-child(6) {
    position: relative;
}

.mngConn_row_investment_name-hover {
    text-decoration: underline;
}

.mngConn_investment-entity {
    font-size: 14px;
    color: #969696;
    /* cursor: pointer; */
}

.mngConn_investment-name {
    /* cursor: pointer; */
}

.mngConn_dropdown {
    padding-left: 10px;
}

.mngConn_dropdown img {
    cursor: pointer;
    margin-right: 10px;
}

.fix-connection-drawer {
    text-align: left;
    /* padding: 100px; */
}

.fix-connection-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 20px;
}

.fix-connection-drawer p {
    text-align: left;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-primary);
}

.fix-connection-drawer .a {
    text-align: center;
}

.fix-connection-drawer p.subscript-link {
    text-align: left;
    font-size: 12px;
}

.fix-connection-drawer .inputs {
    margin: 30px 0 0 0;
    width: 100%;
}

.fix-connection-drawer-button {
    margin-top: 20px;
}

.syncing-row {
    border: 1px solid var(--row-border-color);
}

.syncing-row .progress {
    margin: 0 !important;
}

.syncing-row .progress .line {
    margin-left: -40px;
}

.syncing-row .sync-status-column {
    width: 200px;
    text-align: left;
}

.syncing-row .sync-progress-column {
    width: 150px;
    text-align: left;
}

.manage-investments-row > td:first-child {
    padding-left: 10px;
}

.mngConn_manage-investments-title {
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: center;
    gap: 4px;
    height: 100%;
}

.manage-investments-header > th:first-child {
    padding-left: 10px;
}

.admin-review-desc {
    margin-top: 18px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
    display: flex;
    align-items: center;
    border: none !important;
    height: 100%;
}

.admin-review-desc span {
    position: absolute;
}

.mobile-width-constraint {
    max-width: 75vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mngInv_filter {
    position: absolute;
    right: 60px;
    margin-top: -48px;
}

.mngInv_mobileHeader {
    height: 40px;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-weight: 600;
    width: 100vw;
    left: 0;
    margin: 0;
    background-color: var(--altx-table-header-bg);
    margin-top: 20px;
}

@media (max-width: 480px) {
    .mngConn_tableContainer {
        margin-bottom: 200px;
    }

    .mngConn_component {
        padding: 0px;
        padding-top: 0;
    }

    .mngConn_header {
        margin: 0 0 -10px 15px;
    }

    .mngConn_tableRow td {
        width: fit-content;
    }

    .altx-mobile-table-header-label {
        font-size: 20px !important;
    }

    .sync-status-column {
        width: 53%;
        text-align: center;
        display: inline-block;
        margin: auto;
    }

    .mngConn_row_investment_name {
        display: inline-block;
        width: 80%;
    }

    .mngConn_row_remove {
        display: inline;
        float: right;
    }

    .fix-connection-drawer {
        padding: 20px;
    }

    .mngInv_filter {
        position: relative;
        right: 0px;
        margin-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .mngConn_tableContainer {
        margin-bottom: 200px;
    }

    .mngConn_component {
        padding: 0px;
        padding-top: 0;
    }

    .mngConn_header {
        margin-top: 0;
    }

    .mngConn_tableRow td {
        width: fit-content;
    }

    .mngInv_filter {
        position: relative;
        right: 0px;
        margin-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .mngConn_tableContainer {
        margin-bottom: 100px;
    }

    .mngConn_component {
        padding: 20px;
        padding-top: 20px !important;
    }

    .mngConn_header {
        margin-top: 0;
    }

    .mngConn_tableRow td {
        width: fit-content;
    }

    .mngInv_filter {
        position: relative;
        right: 0px;
        margin-top: 0px;
        flex-direction: column;
        padding-left: 0px;
    }

    .mngInv_mobileHeader {
        width: calc(100vw - 55px);
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .mngConn_component {
        padding: 20px;
        padding-top: 0;
    }

    .mngConn_header {
        margin-top: 0;
    }

    .mngConn_tableRow td {
        width: fit-content;
    }
}
