.addNewAdvisorToFirm-drawer {
    /* padding: 180px 100px; */
    padding: 0px 85px;
}

.addNewAdvisorToFirm-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* font-feature-settings: 'liga' off; */
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 10px;
}

.addNewAdvisorToFirm-drawer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.addNewAdvisorToFirm-drawer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.addNewAdvisorToFirm-cancel {
}

.addNewAdvisorToFirm-cancel span {
    width: 100px;
}

.addNewAdvisorToFirm-drawer h3 {
    padding-top: 20px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 14px;
    margin-top: 14px;
}
