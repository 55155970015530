.docViewer-container {
    position: relative;
    height: calc(100vh - 80px);
    display: flex;
}

.docViewer-viewer {
    position: relative;
    height: 100%;

    /* left: 0;
    top: 0;
    bottom: 0; */
}

.docViewer-drawer {
    position: relative;
    width: auto;
    height: 100%;

    /* right: 0;
    top: 0;
    bottom: 0; */
}

/* ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


::-webkit-scrollbar-track {
    background-color: grey;
}

::-webkit-scrollbar {
    width: 10px;  
    height: 10px; 
} */

.docViewer-failed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.docViewer-editModeControls-container {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 225px;
    background-color: rgba(0, 0, 0, 0.05);
    /* opacity: 0.7; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
}

.docViewer-editModeControls {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    /* background-color: blue; */
}

.docViewer-editModeControls-title {
    white-space: nowrap;
    margin: 10px;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-link);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.docViewer-editModeControls-button {
    height: fit-content;
    padding: 5px;
    width: 100%;
}
