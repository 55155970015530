.cv-container {
    --value-width: 60%;
    position: relative;
    background-color: white;
    display: flex;
    color: var(--color-dark-gray);
    min-width: 300px;
    max-width: 400px;
    padding: 10px 20px;
}

.cv-left {
    width: calc(100% - var(--value-width));
    display: flex;
    flex-direction: column;
    /* padding-left: 20px; */
    justify-content: center;
    margin-right: 30px;
}

.cv-value {
    width: var(--value-width);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 40px;
    color: var(--color-primary);
}

.cv-gainLoss {
    font-weight: 600;
    display: flex;
    align-items: center;
}
