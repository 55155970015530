.clientList-container {
    /* margin: 33px 44px; */
    margin: 40px 40px;
    /* width: calc(340px * 3 + 50px + 200px); */
    /* max-width: calc(340px * 3 + 50px); */
    /* min-width: 850px; */
}

/* HEADER */

.clientList-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.clientList-header div:first-child {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-primary);
}

.clientList-header img {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    cursor: pointer;
}

.clientList-header-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 35px;
}

/* FILTER ROW */

.clientList-filterRow {
    position: relative;
    /* background-color: var(--color-teal); */
}

.clientList-filterRow-filters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 75px);
}

.clientList-filterRow-title {
    font-size: 16px;
    margin-bottom: 5px;
}

.clientList-filterRow-search {
    margin-right: 30px;
    width: 370px;
    min-width: 200px;
}

.clientList-filterRow-search input {
    margin-top: 0;
    height: 39px !important;
    line-height: 39px !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.clientList-filterRow-status {
    margin-right: 30px;
    width: 200px;
}

.clientList-filterRow-dropdown {
    display: flex;
    align-items: center;
    height: 38px;
}

.clientList-filterRow-calls {
}

.clientList-filterRow-callsDue {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 38px;
}

.clientList-filterRow-tableTypeToggle {
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    /* right: 0; */
    /* bottom: 0; */
    /* margin-bottom: 8px; */
}

.clientList-filterRow-tableTypeToggle div {
    cursor: pointer;
}

.clientList-filterRow-tableTypeToggleCardView {
    margin-right: 13px;
}

.clientList-filterRow-tableTypeToggleCardView div div {
    display: flex;
    flex-direction: row;
}

.clientList-filterRow-tableTypeToggleCardView div div div {
    width: 10px;
    height: 10px;
    background-color: var(--color-lighter-gray);
    margin-right: 2px;
    margin-top: 2px;
    border-radius: 3px;
}

.clientList-filterRow-tableTypeToggleGridView div {
    width: 20px;
    height: 5px;
    background-color: var(--color-lighter-gray);
    margin-top: 3px;
}

.clientList-filterRow-tableTypeToggleCardActive div div div {
    background-color: var(--color-teal) !important;
}

.clientList-filterRow-tableTypeToggleGridActive div {
    background-color: var(--color-teal) !important;
}

/* Connection Requests section */

.clientList-connectionRequests {
}

.clientList-connectionRequests-header,
.clientList-clients-header,
.clientList-table-header {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: var(--color-dark-gray);
}

.clientList-tableContainer {
}

/* My Clients section */

/* Account setup */

.account-setup {
    margin-top: 45px;
}

.account-setup h1 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    color: var(--color-primary);
    margin: 0;
    padding: 0;
}

.add-client {
    position: relative;
    margin-top: 23px;
    width: 679px;
    height: 86px;
    background: var(--color-accent);
    display: flex;
    gap: 40px;
    padding: 0px 24px;
    align-items: center;
}

.add-client:hover {
    cursor: pointer;
}

.add-client span {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-primary);
}

.arrow {
    position: absolute;
    right: 30px;
    top: 35px;
}

/* Account setup */
