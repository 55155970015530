/* .investmentDetails-container {
    position: relative;
} */

/* .investmentDetails-meta {
    padding: 30px 0px 30px 60px;
    padding-bottom: 0;
    max-width: 65%;
    min-width: 55%;
    float: left;
} */

.investmentDetails-curValue {
    padding: 40px 40px 0 0;
    padding-bottom: 0;
    float: right;
}

.investmentDetails-fullSize {
    padding: 0px 40px;
}

.investmentDetails-statusBlock {
    margin: 0;
    margin-left: 60px;
    position: relative;
    color: var(--color-light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 30px;
    background-color: white;
    box-shadow: var(--box-shadow);
    float: left;
}

.investmentDetails-connectionStatusBlock {
    margin: 0;
    position: relative;
    color: var(--color-light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* min-height: 30px; */
    /* background-color: white; */
    /* box-shadow: var(--box-shadow); */
    float: left;
}

@media (max-width: 480px) {
    .investmentDetails-meta {
        padding: 20px;
        width: 100%;
    }
}
