$body-input-width: 210px;
$body-input-width-diff: 22px;

.main {
    .x {
        &:hover {
            transform: scale(1.1);
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .tran {
        .tranHeader {
            height: 30px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;

            .x {
                cursor: pointer;
            }
        }

        .tranBodyContainer {
            background-color: white;
            box-shadow: var(--box-shadow);
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 4px;

            .type {
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 10px;

                .typeSelect {
                    // width: 50% !important;
                    width: calc(#{$body-input-width} + #{$body-input-width-diff}) !important;
                }
            }

            .amount {
                display: flex;
                align-items: center;
                justify-content: space-between;

                input {
                    // width: 50% !important;
                    width: $body-input-width !important;
                }
            }
        }
    }

    .addTransaction {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;

        .x {
            width: 20px;
            height: 20px;
            cursor: pointer;
            color: var(--color-link);
        }
    }
}
