$iconMarginLeft: 28px;

.main {
    position: relative;
    /* height: 90%; */
    // background-color: cyan;
    // margin: 40px 60px;

    .checkmark {
        margin-left: $iconMarginLeft;
        path {
            fill: green;
        }
    }

    .x {
        margin-left: $iconMarginLeft;
        path {
            fill: red;
        }
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        margin: 0;
    }

    .altxEmail path {
        fill: var(--color-primary);
    }
}
