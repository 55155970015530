.docJobs-component {
    position: relative;
}

.docJobs-actions {
    display: flex;
    align-items: center;
    margin: 10px;
}

.docJobs-actions-select {
    flex-grow: 1;
}

.docJobs-actions-send button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: auto;
    padding: 0px 12px;
    font-size: 16px;
    background-color: var(--color-link);
    transition: color 0.2s;
}

.docJobs-actions-send path {
    fill: var(--color-title);
}

.docJobs-actions-send button:hover {
    background-color: var(--color-link-hover);
}

.docJobs-list {
    list-style-type: none;
    padding: 0;
}

.docJobs-item {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.docJobs-item-header {
    display: flex;
    justify-content: space-between;
}

.docJobs-item-type {
    font-weight: bold;
    color: #333;
}

.docJobs-item-info,
.docJobs-item-result {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #666;
}

.docJobs-more {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.docJobs-more a {
    margin: 0 0.5rem;
}

.docJobs-loading {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}
