.donutComponent {
    color: var(--color-dark-gray);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* float: right; */
    width: calc(50% - 160px);
    margin: 20px;
    background-color: transparent !important;
    box-shadow: none !important;
    /* min-height: 200px; */
    padding: 20px !important;
}

.donutLegend {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}

.donutLegendItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.donutLegendSquare {
    --size: 15px;

    width: var(--size) !important;
    height: var(--size) !important;
    aspect-ratio: 1/1;

    position: relative;
    margin-left: 20px;
    margin-right: 20px;
}

.donutLegendText {
    margin-top: -2px;
    font-size: 15px;
    font-weight: normal;
}

.donutGraph {
    position: relative;
    width: 60%;
}

.donutText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.donutText div:first-child {
    font-size: 46px;
    font-weight: bold;
}

.donutText div:last-child {
    margin-top: -5px;
    font-size: 22px;
}

@media (max-width: 1024px) {
    .donutComponent {
        width: calc(100% - 40px);
        margin: 0;
        padding: 20px !important;
    }
}
