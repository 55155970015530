.supertoolContainer {
    /* display: inline-block; */
    position: relative;
    right: 0;
    /*     
    display: flex;
    align-content: center; */

    /* flex: 1; */
    /* background-color: blue; */

    /* height: 600px; */
    width: 600px;
    width: 100%;
    right: 0;
}

.supertoolItems {
    position: relative;
    display: flex;
    align-items: center;
    /* background-color: green; */
    width: 100%;
    right: 0;
}

.supertoolSeparator {
    display: inline-block;
    margin: 0 10px;
    height: 24px;
    margin-bottom: -4px;
    width: 1px;
    background-color: rgb(220, 220, 220);
}

.supertoolUserStatusBar {
    height: 24px;
    width: 140px;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-justify: center;
    margin: 5px;
}

.supertoolUserDeactivationBar {
    height: 24px;
    width: 140px;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-justify: center;
    margin: 5px;
}

.titleContainer {
    /* background-color: red; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* vertical-align: center; */
    /* overflow: hidden; */
}

.supertoolCreateSection {
    display: flex;
    /* margin-left: auto; */
    flex: 1;
    justify-content: right;
}

.supertoolCreate {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    align-content: center;
    line-height: 20px;
    flex-wrap: nowrap;
}

.supertoolCreate:hover {
    transform: scale(1.1);
}

/* .supertoolCreate > img {
    margin-right: 10px;

} */

/* #supertoolAddEntity {
    display: inline;

} */
