.main {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow-x: unset;
    overflow-y: auto;

    .stage {
        // background-color: purple;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow-x: unset;
        overflow-y: visible;
    }

    .topRightButtons {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 100;
    }

    .typeDropdown {
        width: 200px;
    }
}

.bulkTrans_fieldname_table {
    border-collapse: separate;
    border-spacing: 0;
    border-left: 1px solid var(--color-dark-gray);
    th,
    td {
        border: 1px solid var(--color-dark-gray);
        border-left: none;
        text-align: left;
        padding: 3px 15px;
    }
}

.bulk_mngr_header_title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 700;
}

.bulk_mngr_subtitle {
    color: var(--color-primary);
    font-size: 18px;
}
