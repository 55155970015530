.performanceOverview_component {
    display: flex;
    flex-direction: row;
    margin: 0 !important;
    margin-top: 20px !important;
    height: fit-content;
}

.performanceOverview_metric {
    width: var(--performance-overview-metric-width);
    padding-left: 20px;
    padding-right: 20px;
}

.performanceOverview_metric:first-child {
    padding-left: 0px;
}

.performanceOverview_metric:last-child {
    padding-right: 0px;
}

.performanceOverview_metricDivider {
    border-right: 1px solid #c4c4c4;
}

/* performanceOverview_metric key and value */
.performanceOverview_metric div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.performanceOverview_metricKey {
    --key-color: var(--color-primary);
    color: var(--key-color);
    font-weight: bold;
    font-size: 40px;
}

.performanceOverview_metricValue {
    --value-color: var(--color-dark-gray);
    color: var(--value-color);
    font-weight: normal;
    font-size: 16px;
}

@media (max-width: 480px) {
    .performanceOverview_component {
        width: calc(100% - 80px) !important;
    }

    .performanceOverview_metricKey {
        font-size: 30px;
    }

    .performance-summary-table tbody tr th:first-child {
        display: none;
    }
}
