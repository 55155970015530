.clientCard-component {
    --pad: 18px;

    position: relative;
    width: var(--clientCard-width);
    height: var(--clientCard-height);
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.02);
    color: var(--color-dark-gray);
    outline: 1px solid #ececec;

    display: inline-block;
    border-top: 2px solid var(--clientCard-statusColor);
    background-color: var(--clientCard-backgroundColor);
}

.clientCard-settings {
    cursor: pointer;
    position: absolute;
    right: var(--pad);
    top: var(--pad);
    height: 25px;
    width: 25px;
}

.clientCard-settings:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.clientCard-name {
    position: absolute;
    left: 5%;
    right: 30%;
    top: 7%;
    bottom: 63%;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-primary);
    overflow: hidden;
}

.clientCard-message {
    position: absolute;
    left: var(--pad);
    top: 43px;
    font-size: 16px;
    width: 195px;
}

.clientCard-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: var(--pad);
    left: var(--pad);
    font-size: 16px;
}

.clientCard-callsDue {
}

.clientCard-documents {
    position: absolute;
    height: 100px;
    /* overflow: hidden; */
    width: 375px;
    top: 25px;

    /* bottom: 74px; */
    /* left: 18px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: -20px;
    /* margin-top: -20px; */
}

.clientCard-countData {
    position: absolute;
    height: 67px;
    width: 250px;
    top: 88px;
    bottom: 74px;
    left: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.clientCard-callsDue,
.clientCard-investments {
    display: flex;
    flex-direction: row;
}

.clientCard-callsDue div,
.clientCard-investments div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 30px;
}

/* number on the info element */
.clientCard-callsDue div:first-child,
.clientCard-investments div:first-child {
    margin-right: 8px;
    height: 30px;
    width: 30px;
    font-size: var(--pad);
    font-weight: bold;
    border-radius: 2px;
}

.clientCard-callsDue div:first-child {
    color: white;
}

.clientCard-investments div:first-child {
    color: var(--color-primary);
}

.clientCard-donut,
.clientCard-donut-low {
    position: absolute;
    bottom: 65px;
    right: 18px;
    width: 81px;
    height: 81px;
    z-index: 10;
}

.clientCard-donut {
    bottom: 65px;
}

.clientCard-donut-low {
    bottom: 10px;
}

.clientCard-options {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: var(--pad);
    bottom: 23px;
}

.clientCard-optionsButton {
    margin-left: 35px;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}
