.toggleBar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #ccc;
    color: white;
    align-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
}

.optionContainer {
    border-radius: 6px;
    color: white;
    text-align: center;
    line-height: normal;
    align-content: center;
    text-align: center;
    display: inline;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;

    /* background-color: red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    background-color: var(--color-link);
    position: absolute;
    height: 100%;
    border-radius: 6px;
    transition: left 0.2s ease-in-out;
}
