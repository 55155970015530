.clientDonutComponent {
    color: var(--color-dark-gray);
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    /* background-color: red; */
}

.clientDonutGraph {
    position: relative;
    /* background-color: yellow; */
    /* width: 60%; */
}

.clientDonutText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.clientDonutText div:first-child {
    font-size: 15px;
    font-weight: bold;
}

.clientDonutText div:last-child {
    margin-top: -5px;
    font-size: 10px;
}
