.main {
    position: relative;
    box-shadow: var(--box-shadow);
    background-color: #ffffff;
    // background-color: #f0f0f0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    padding: 20px;
    margin: -20px;
    width: 100%;
    align-items: center;
    // height: 100%;

    gap: 10px;

    .header {
        font-size: 1.2em;
        font-weight: bold;
    }

    .logJson {
        box-shadow: var(--box-shadow);
        background-color: #f0f0f0;
        padding: 10px;
    }

    .log {
        box-shadow: var(--box-shadow);
        width: 100%;
        background-color: #f0f0f0;
        padding: 10px;

        .logHeader {
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .initiator {
            font-size: 0.8em;
        }

        .actionDetails {
            margin-top: 10px;
        }
    }

    .refreshButton {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
    .exitButton {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.loadTextContainer {
    width: 100%;
    text-align: center;

    .loadText {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: var(--color-link);
        }
    }
}
