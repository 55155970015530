.manage-account-container {
    padding: 40px 20px 20px 40px;
    position: relative;
}

.split-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.content-elements .a {
    text-align: right;
}

.profile-drawer {
    padding: 180px 100px;
}

.profile-drawer h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 20px;
}

.profile-drawer button {
    width: 100%;
    margin: 20px 0;
    margin-top: 20px;
}

.manage-account-container h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'liga' off;
    color: var(--color-primary);
    margin-bottom: 10px;
}

.manage-account-container .profile {
    margin-right: 20px;
    display: inline-block;
    width: 50%;
}

.manage-account-container .security {
    position: fixed;
    display: inline-block;
    height: 200px;
    padding: 0px 20px;
    display: inline;
    width: 35%;
    border-left: solid 1px #ddd;
}

.profile label,
.security label {
    line-height: 32px;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-primary);
}

.profile div,
.security div {
    margin-bottom: 20px;
}

.profile div div,
.security div div {
    display: inline;
}

.profile div div .a,
.security div div .a {
    float: right;
}

.investment-accounts {
    margin-top: 4vw;
}

.investment-accounts h1 {
    width: inherit;
    display: inline;
    font-size: 23px;
    font-weight: 600;
}

.investment-accounts h1 div {
    margin-left: 20px;
    display: inline;
    font-weight: 400;
    font-size: 16px;
}

.accounts-list {
    overflow-y: auto;
}

.accounts-list .accounts-list-item {
    padding: 20px;
    border-bottom: solid 1px #ddd;
}

.accounts-list .accounts-list-item .links {
    position: relative;
    margin: auto;
    float: right;
}

.accounts-list .accounts-list-item .links .a {
    display: inline;
    margin-right: 20px;
}

.add-account {
    position: relative;
    text-align: center;
    vertical-align: middle;
    display: inline-block !important;
    width: 20px;
    height: 20px;
    font-weight: 600;
    font-size: 20px;
    background-color: var(--color-link);
    color: #fff;
    border-radius: 13px;
    margin-right: 5px;
    padding-top: 60px;
}

.add-account span {
    position: relative;
    top: -1.5px;
    left: 0.5px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.new-entity {
    height: 36px;
    line-height: 36px;
    font-size: var(--font-size);
    font-weight: 400;
    padding: 0 10px;
    padding-left: 34px;
    margin: 5px 0;
    clear: both;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-image: url('/public/images/icons/plus.png');
    background-position: 13px 4px;
    background-repeat: no-repeat;
}

.profileLinkText {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    display: inline;
}

.deactivated:hover {
    color: red !important;
}

@media (max-width: 480px) {
    .split-content {
        display: block !important;
    }

    .split-content div {
        width: 100%;
    }

    .manage-account-container .profile {
        position: relative;
        display: block;
        width: 100%;
        border-left: none;
        border-bottom: solid 1px #ccc;
        margin-bottom: 10px;
    }

    .manage-account-container .security {
        position: relative;
        display: block;
        width: 100%;
        border-left: none;
        padding-left: 0;
        height: fit-content;
    }

    .investment-accounts {
        margin-top: 4vh;
    }

    .accounts-list-item {
        padding: 0 10px 20px 10px !important;
        margin-bottom: 20px !important;
    }

    .accounts-list .accounts-list-item .links {
        margin-top: 20px;
        float: none;
    }

    .accounts-list .accounts-list-item .links .a {
        margin-right: 10px;
    }

    .manage-account-container {
        padding: 20px;
        margin-top: 20px;
        padding-top: 0px;
        height: calc(100% + 320px) !important;
    }

    .accounts-list {
        max-height: calc(100% + 100px);
    }

    .profile-drawer {
        padding: 0px 70px 50px 50px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .manage-account-container {
        padding: 20px;
    }

    .accounts-list {
        max-height: calc(100% + 100px);
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .manage-account-container {
        padding: 20px;
    }

    .accounts-list {
        max-height: calc(100% + 100px);
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .manage-account-container {
        padding: 20px;
    }

    .accounts-list {
        max-height: calc(100% + 100px);
    }
}
