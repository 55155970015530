.hold-button {
    position: relative;
}

.progress-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    /* Adjust this according to your needs */
    height: 90%;
    /* Adjust this according to your needs */
    transform: translate(-50%, -50%);
}

.hold-progress {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    /* Make sure button clicks/taps pass through this overlay */
}

.tooltip {
    display: none;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 1000;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.hold-button:hover .tooltip {
    display: block;
}
