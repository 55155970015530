.cccComponent {
    --cccCircle-size-percent: 65%;
    --cccCircle-size: 300px;
    --pad: 20px;
    --font-size: 24px;
    --labelMoneyFontSize: 30px;
    --cccLabel-offset: calc(var(--labelMoneyFontSize) / 3);
    --circle-color: #5891f0;
    --fill-height: calc(var(--cccCircle-size-percent) * var(--cccPercent) / 100);
    --line-width: 25%;

    display: flex;
    justify-content: center;
    flex-direction: row;
    color: var(--color-dark-gray);
    font-size: var(--font-size);
    padding: 28px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    min-width: 200px;
    max-width: var(--cccCircle-size-percent) !important;
}

.cccCircleContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--cccCircle-size-percent);
    /* height: var(--cccCircle-size); */
    aspect-ratio: 1 !important;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100 !important;
}

.cccCircle {
    position: absolute;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    background-color: var(--circle-color);
    opacity: 0.25;
    overflow: hidden;
}

.cccCircleFilled {
    position: absolute;
    width: 100%;
    height: var(--cccPercent);
    bottom: 0;
    background-color: var(--circle-color);
    opacity: 1;
}

.cccCircleText {
    position: absolute;
    color: white !important;
    /* z-index: 1000 !important; */
    z-index: 30000 !important;
}

.cccCircleText div {
    /* z-index: 1000 !important; */
    z-index: 30000 !important;
}

.cccCircleText div:first-child {
    text-align: center;
    font-size: var(--labelMoneyFontSize) !important;
    font-weight: bold;
}

.cccLabelContainer {
    position: relative;
    display: flex;
    width: calc(100% - var(--cccCircle-size-percent));
    z-index: 200 !important;
}

.cccLabelLine {
    position: absolute;
    width: var(--line-width) !important;
    width: calc(var(--cccCircle-size-percent) / 2) !important;
    border-top: 1px solid var(--circle-color);
    margin-right: 10px;
    /* margin-left: 15px; */
    right: calc(100% - 20px);
    top: calc(100% - var(--cccPercent));
}

.cccLabelText {
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: calc(-1 * var(--font-size) / 1);
    top: calc(100% - var(--cccPercent));
    margin-left: 20px;
}

.cccLabelText div:first-child {
    font-weight: bold;
    color: var(--circle-color);
}

.cccLabelText div:last-child {
    top: -7px;
    position: relative;
    flex-wrap: nowrap;
    white-space: nowrap;
}

@media (min-width: 100px) and (max-width: 1023) {
    .cccCircleText {
        margin-left: 20px;
    }

    .cccCircleText div:first-child {
        text-align: left;
    }
}

@media (max-width: 1024px) {
    .cccCircleText,
    .cccCircleText div:first-child {
        font-size: 20px;
    }
}
