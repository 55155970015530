.clientBar-component {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 130px;
    height: 100% !important;
}

.clientBar-component > div {
    /* height: 25px; */
}
