.pagTable {
    position: relative;
    margin: 40px 60px 20px;
    color: var(--color-dark-gray) !important;
    /* overflow: scroll; */
    overflow: auto;
}

.pagTable_headerContainer {
    color: var(--color-dark-gray);
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
}

.pagTable_headerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pagTable_headerText {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-primary);
    /* margin-bottom: 10px; */
}

.pagTable_headerDropdown {
    /* margin-right: 15px; */
    /* margin-top: 6px; */
}

.pagTable_headerControls {
    /* z-index: 100; */
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* width: 100%; */
}

.pagTable_datePickerText {
    position: absolute;
    color: var(--color-dark-gray);
    font-size: 14px;
    top: -15px;
}

.pagTable_datePickerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: inline;
}

.pagTable_datePicker_to {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    /* margin-top: 5px; */
    /* margin-top: 20px; */
}

.pageTable_filters {
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
}

.pagTable_error {
    color: var(--color-dark-red);
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
}

.altx-table-row-disabled {
    background-color: var(--color-very-light-gray) !important;
}

@media (max-width: 480px) {
    .pagTable_headerDropdown {
        /* margin-right: 10px;
        margin-top: 25px; */
    }

    .pagTable_footContainer {
        margin-bottom: 30px;
    }

    .pagTable_datePicker_to {
        display: inline;
        margin-top: 20px !important;
    }

    .pageTable_filters {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: flex-end; */
        /* flex-direction: column; */
        /* align-items: center;
        justify-content: center; */
    }

    .pagTable-exportCsvContainer {
        /* margin-top: -120px !important; */
    }
}

@media (max-width: 1024px) {
    .pageTable_filters {
        /* width: 100%;
        position: relative;
        display: flex;    */
        flex-direction: column;
        /* align-items: center; */
    }

    .pagTable_typesDropdown {
        width: calc(100%);
        /* width: calc(100% - 10px); */
        /* margin-left: 10px; */
    }

    .pagTable {
        margin: 20px;
        height: fit-content;
    }

    .pagTable .clear {
        display: block !important;
        height: 50px !important;
    }

    .pagTable_headerContainer {
        width: 100%;
        display: block;
    }

    .pagTable_headerContainer .capCalls_dueMetContainer {
        float: right;
    }

    .pagTable_headerControls {
        margin-top: 20px;
        width: 100%;
        display: flex;
    }

    .pagTable_datePickers {
        margin-top: 15px;
        width: calc(100%);
        /* width: calc(100% - 10px); */
        display: flex;
    }

    .dropdown-container {
        width: 100% !important;
    }

    .pagTable_headerDropdown {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .pagTable_datePickerText {
        position: relative;
        top: -2px;
    }

    .pagTable_datePicker_to {
        display: inline;
        margin-top: 20px;
    }

    .pagTable_footContainer {
        margin-bottom: 0;
    }

    .pagTable_footRange {
        margin-left: 10px;
    }

    .pagTable_footContainer {
        justify-content: center;
    }

    .pagTable-exportCsvContainer {
        /* margin-top: 30px !important; */
    }
}

.pagTable-exportCsvContainer {
    /* position: absolute; */
    /* width: 200px; */
    /* left: calc(50% - 100px) !important; */
    /* width: 100%; */

    /* display: flex;
    align-items: center;
    justify-content: center; */

    /* margin-top: -40px; */
    /* left: 0; */
}

.pagTable-exportCsv {
    /* margin-top: 10px !important; */
    /* position: absolute; */
    width: 130px;
    cursor: pointer;
    /* z-index: 1; */
}

.pagTable_selectFilters_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 65px;
    /* z-index: 10 !important; */
}

.pagTable_selectFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 10 !important;
}
