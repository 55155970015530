.tab {
    /* min-width: 150px; */
    padding-left: 10px;
    padding-right: 10px;
    border-width: 3px;
    border-style: solid;
    border-bottom: none;
    height: 30px;
    font-size: 20px;
    vertical-align: center;
    text-align: center;
    cursor: pointer;
    margin: 0;
}

.tab.tab-active {
    color: white;
    background-color: var(--color-primary);
    border-color: var(--color-background);
}

.tab.tab-inactive {
    color: var(--color-primary);
    border-color: var(--color-light-separator);
}

.tab.tab-inactive:hover {
    color: white;
    background-color: var(--color-primary);
    border-color: var(--color-background);
}

.tab-switcher {
    width: 100%;
    display: flex;
    border-bottom: 3px solid var(--color-light-separator);
    margin: 0;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
}

.tab-disabled,
.tab-disabled:hover {
    background-color: var(--color-light-gray) !important;
    color: var(--color-light-separator) !important;
    cursor: default;
}

@media (max-width: 480px) {
    .tab-switcher {
        /* width: fit-content !important; */
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .tab {
        font-size: 16px !important;
        /* min-width: 100px !important; */
        width: calc(50% - 40px);
        padding-top: 10px !important;
    }
}
