.multiPageTabWidget {
    padding: 40px 60px;
    --triangle-size: 10px;
    --arrow-color: #edc30c;
    --color-light-gray: #cbcbcb;
    --table-header-color: var(--color-accent);
    --row-border-color: #adadad;
    --investment-row-color: #f1f5fb;
}

#multiPageTabWidgetContainer {
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 480px) {
    .multiPageTabWidget {
        padding: 0px;
        padding-top: 0;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .multiPageTabWidget {
        padding: 0px;
        padding-top: 0;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .multiPageTabWidget {
        padding: 20px;
        padding-top: 20px !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .multiPageTabWidget {
        padding: 20px;
        padding-top: 0;
    }
}
