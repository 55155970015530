.sharedStyle {
    background-color: white;
    margin-bottom: 5px;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
}

.main {
    a:hover {
        background-color: var(--color-link);
        color: white;

        .singleDocName {
            color: white;
        }

        .singleDocTypeRow {
            color: white;
        }
    }
}

.singleDocTitle {
    margin-bottom: 10px;
}

.singleDoc {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;

    .singleDocTypeRow {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: gray;
    }

    .singleDocName {
        font-size: 18px;
        text-align: center;
        color: black;
    }
}

.singleDocShowAll {
    font-size: 14px;
    color: gray;
    cursor: pointer;
    margin-left: 10px;
}
