.manInv-detailsContainer {
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    height: 80%;
    background-color: blue;
    width: 350px !important;
    bottom: 20px;
}

.manInv-detailsContainer input {
    height: 38px !important;
}

.manInv-detailsContainer label {
    font-size: 16px;
    margin-top: 0 !important;
}

.manInv-detailsContainer input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
}

.box {
    max-width: 600px !important;
}
