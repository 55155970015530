.docMeta-component {
    position: relative;
}

.docMeta-component label {
    line-height: unset;
    /* font-weight: bold; */
    /* float: left; */
    /* width: 100px; */
    margin-right: 10px;
    white-space: nowrap;
}

.docMeta-row {
    display: flex;
    align-items: center;
}

.docMeta-items {
    margin: 5px;
}

.docMeta-checkIcon {
    margin-left: 5px;
}

.docMeta-checkIcon.valid path {
    fill: var(--color-dark-green);
}

.docMeta-checkIcon.invalid path {
    fill: var(--color-dark-red);
}

.docMeta-header {
    display: flex;
    align-items: center;
    gap: 10px;
    /* justify-content: space-between; */
}

.docMeta-rightComponent {
    margin-left: 8px;
}

.docMeta-x {
    display: none;
}

.docMeta-untagged path {
    fill: var(--color-dark-gray);
}

.docMeta-tagged path {
    fill: var(--color-dark-green);
}

.docMeta-x path {
    fill: var(--color-dark-red);
}

.docMeta-x:hover {
    cursor: pointer;
}

.docMeta-rightComponent:hover .docMeta-x {
    display: block;
}

.docMeta-rightComponent:hover .docMeta-tagged {
    display: none;
}
