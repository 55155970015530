.investmentDetails-container {
    position: relative;
}

.investmentDetails-meta {
    padding: 30px 60px;
    padding-bottom: 0;
    width: calc(50% - 40px);
    float: left;
}

.details-main {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
}

.investmentDetails-back-button {
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
    color: var(--color-link);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 10rem;
    margin-bottom: 10px;
}

.investmentDetails-back-button a {
    color: var(--color-link);
}

.investmentDetails-back-button:hover {
    color: var(--color-link-hover);
}

.investmentDetails-type {
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: var(--color-text);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}

.investmentDetails-user-name {
    /* margin-bottom: 10px; */
    color: var(--color-light-gray);
    font-weight: 600;
    font-size: 18px;
}

.investmentDetails-account {
    /* margin-bottom: 10px; */
    color: var(--color-light-gray);
    font-weight: 600;
    font-size: 18px;
}

.investmentDetails-name-container {
    position: relative;
    margin-left: -1px;
    font-size: 36px !important;
    font-weight: 700;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.investmentDetails-name {
    color: var(--color-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.investmentDetails-year {
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.investmentDetails-status {
    color: #edc30c;
}

#mobile-investment-details-table .altx-mobile-table .altx-mobile-table-cell label {
    display: inline-block;
}

.investmentDetails-fullSize {
    padding: 0px 40px;
}

@media (max-width: 480px) {
    .investmentDetails-meta {
        padding: 20px;
        width: 100%;
    }

    #investment-details-table {
        margin: 0;
        padding: 20px;
    }
}
