.docs_newDocs {
    display: inline-flex;
    margin-left: 20px;
    font-size: 14px !important;
    color: var(--color-text-light);
    font-weight: 400;
    align-items: center;
    cursor: pointer;
}

.docs_newDocs img {
    margin-right: 8px;
    width: 15px;
    height: 15px;
}

.docs-ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 340px;
    margin: 0;
}

.docTable-checkIcon {
    /* margin-left: 5px; */
    /* margin-right: 10px; */
}

.docTable-gearIcon {
    /* margin-left: 5px; */
    margin-right: 10px;
}

.docTable-checkIcon.valid path {
    fill: var(--color-dark-green);
}

.docTable-checkIcon.invalid path {
    fill: var(--color-dark-red);
}

.docTable-gearIcon.true path {
    fill: var(--color-dark-green);
}

.docTable-gearIcon.false path {
    fill: var(--color-dark-gray);
}
