.bulkDoc-component {
    width: 950px;
    /* height: 550px; */
    border-radius: 17px;
    position: relative;
    overflow: hidden;
}

.bulkDoc-innerComponent {
    padding: 20px 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bulkDoc-header-title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 700;
}

.bulkDoc-dragging {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-link);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 95%;
}

.bulkDoc-docList {
    width: 715px;
    height: 415px;
    overflow: hidden;
    position: relative;
    border: 1px solid var(--color-lighter-gray);
}

.bulkDoc-docList-header {
    height: 35px;
    background-color: var(--color-primary);
    color: white;
    display: flex;
    align-items: center;
}

.bulkDoc-docList-header > div {
    color: white;
    margin-left: 15px;
    margin-right: 15px;
}

.bulkDoc-docList-body {
    height: calc(100% - 35px);
}

.bulkDoc-docRow {
    /* height: 50px; */
    border-bottom: 1px solid var(--color-accent);
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
    padding: 10px 0;
}

.bulkDoc-docRow-name {
    width: 400px;
}

.bulkDoc-check {
    background-color: var(--c);
    width: calc(var(--w) * 1px);
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bulkDoc-check > div {
    border: 2px solid white;
    border-right: none;
    border-top: none;
    width: calc(var(--w) / 2.5 * 1px);
    height: calc(var(--w) / 5 * 1px);
    transform: rotate(-40deg);
    margin-top: -4px;
}

/* icons */
.bulkDoc-close {
    position: absolute;
    top: 27px;
    right: 27px;
    padding: 13px;
    background-image: url('/public/images/icons/closeBlue.png');
    background-position: center;
    background-size: cover;
    cursor: pointer;
}

.bulkDoc-docIcon-blue {
    padding: 85px 70px;
    background-image: url('/public/images/icons/document_blue.png');
    background-position: center;
    background-size: cover;
}

.bulkDoc-docIcon-white {
    padding: 85px 70px;
    background-image: url('/public/images/icons/document_white.png');
    background-position: center;
    background-size: cover;
}
