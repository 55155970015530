.users-container .pagTable_headerText {
    font-size: 16px !important;
    width: 100%;
}

.users-container .pagTable_headerText h1 {
    margin-bottom: 10px;
}

.users-container div h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-dark-gray);
}

.users-container div label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
}

.users-container div input {
    width: 30%;
}

.users-container div span {
    /* position: absolute; */
}

.users-row {
    height: 50px;
}

.new-user {
    display: inline;
    float: right;
    height: 36px;
    line-height: 36px;
    font-size: var(--font-size);
    font-weight: 400;
    padding: 0 10px;
    padding-left: 34px;
    margin: 5px 0;
    clear: both;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-image: url('/public/images/icons/plus.png');
    background-position: 10px 9px;
    background-repeat: no-repeat;
}

.users-table-container {
    margin-top: -20px;
}

.arrow-down {
    color: #edc30c;
    position: absolute;
    margin-left: -25px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.arrow-up {
    color: #edc30c;
    position: absolute;
    margin-left: -25px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);

    position: relative !important;
    left: 0 !important;
    top: 0;
    border-left: none;
    margin-right: 10px;
}

.expanded-row {
    background: rgba(211, 225, 249, 0.5);
}

.new-user-drawer {
    padding: 60px 75px 20px 75px;
}

.new-user-drawer h1 {
    margin-bottom: 0 !important;
    text-align: left;
}

.new-user-drawer .input-group {
    left: 0;
    margin-top: 0;
}

.new-user-drawer .input-group label {
    margin-bottom: 0;
}

.new-user-drawer .input-group input {
    height: 18px !important;
}

.new-user-drawer .input-group span {
    margin-bottom: 40px !important;
}

.new-user-drawer .input-group span .error-visible {
    font-size: 14px !important;
    margin-bottom: 40px !important;
}

.new-user-drawer .input-group .dropdown-container {
    width: 100%;
}

.remove-user-container label {
    text-align: center !important;
}

.remove-user-container div {
    width: 100% !important;
}

.remove-user-container div input {
    width: 100% !important;
}
