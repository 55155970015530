.manageAccount-container {
    width: full;
    display: flex;
    justify-content: space-between;
    margin-left: 60px;
    margin-top: 32px;
    font-weight: 600;
}

.manageAccount-container h1 {
    margin: 0px;
    padding: 0px;
    font-size: 23px;
    color: var(--color-primary);
}

.manageAccount-container label {
    margin-top: 26px;
    font-size: 18px;
    color: var(--color-primary);
}

.manageAccount-content {
    width: 46%;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
}

.manageAccount-content label {
    color: var(--color-primary);
}

.content-buttons {
    font-size: 14px;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
}

.content-buttons:hover {
    cursor: pointer;
    color: #00bbb5;
}

.content-elements {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text);
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 28px;
}

.separator-container {
    width: 8%;
    display: flex;
    margin-top: 12px;
}

.manageAccount-separator {
    display: inline-block;
    margin-left: 10px;
    height: full;
    width: 1px;
    background-color: rgb(220, 220, 220);
}

.advisorClientList h1 {
    margin: 0px;
    padding: 0px;
    font-size: 23px;
    color: var(--color-primary);
}
