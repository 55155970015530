.advisor-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-div {
    width: 416px;
}

.content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.advisor-container h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0px !important;
}
.advisor-container h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}

.advisor-container p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
}

.input-div {
    margin-top: 35px;
    position: relative;
    text-align: justify;
    width: 311px;
}

.input-div label {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    font-feature-settings: 'liga' off;
    color: #fff;
    margin-bottom: 10px;
}

.input-div input,
.input-div select {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid var(--color-border) !important;
    border-radius: 3px;
    font-size: 14px !important;
    padding: 10px 0px 10px 10px;
    width: 311px;
    cursor: pointer;
}

.input-div select:focus,
.input-div input:focus {
    outline: none;
}

.input-div select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='28' viewBox='0 0 24 24' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.input-div svg {
    position: absolute;
    right: 8px;
    top: 12px;
}

.advisor-container button {
    margin-top: 45px;
    width: 135px;
}
