.exportDet-container {
    --container-pad: 40px;
    --row-label-width: 150px;

    position: relative;
    left: var(--container-pad);
    width: calc(100% - var(--container-pad) * 2);
}

.exportDet-meta {
    padding: 30px 0px 30px 0px;
}

.exportDet-back-button {
    font-size: 18px;
    color: var(--color-link);
}

.exportDet-type {
    margin-top: 20px;
    color: var(--color-text);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}

.exportDet-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    font-size: 18px;
}

.exportDet-label {
    position: relative;
    color: var(--color-text);
    width: var(--row-label-width) !important;
    display: flex;
    align-items: center;
}

.exportDet-body {
    position: relative;
    color: var(--color-text);
    width: calc(100% - var(--container-pad) * 2 - var(--row-label-width));
}

.exportDet-attachmentContainer {
    width: 100%;
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: max-content max-content;

    justify-content: space-between;
    grid-gap: 20px;
}

.exportDet-attachment {
    display: flex;
    align-items: center;
    background-color: var(--color-light-gray);
    background-color: #cbcbcb;
    padding: 10px 20px 10px 20px;
    border-radius: 50px;
    margin-right: 10px;
}

@media (max-width: 480px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content max-content;
    }
}

@media (min-width: 1024px) and (max-width: 1224px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content;
    }
}

@media (min-width: 1500px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content max-content max-content;
    }
}

@media (min-width: 1800px) {
    .exportDet-attachmentContainer {
        grid-template-columns: max-content max-content max-content max-content;
    }
}

.exportDet-curValue {
    padding: 90px 40px 0 0;
    padding-bottom: 0;
    float: right;
}

.exportDet-name {
    font-size: 36px;
    color: var(--color-primary);
    font-weight: 700;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 30px;
    /* height: 60px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.exportDet-year {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 18px;
}

.exportDet-fullSize {
    padding: 0px 40px;
}

.exportDet-statusBlock {
    margin: 0;
    margin-left: 60px;
    position: relative;
    color: var(--color-light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 30px;
    background-color: white;
    box-shadow: var(--box-shadow);
    float: left;
}

@media (max-width: 480px) {
    .exportDet-meta {
        padding: 20px;
        width: 100%;
    }
}

.exportDet-messageContainer {
    /* display: flex; */
}

.exportDet-messageContainer p {
    text-align: left;
    margin-bottom: 10px;
}

.exportDet-messageBodyText {
    font-size: 18px;
    color: var(--color-text);
    padding: 20px;
    overflow: auto;
    background-color: var(--color-hover);
}
.exportDet-messageBodyText p {
    margin: 0;
    text-align: left;
}
