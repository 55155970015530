.dropdown-container {
    --select-height: 36px;
    position: relative;
}

.dropdown-option {
    /* background-color: white; */
    height: var(--select-height);
    line-height: var(--select-height);
    white-space: nowrap;
    color: var(--color-primary);
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
    margin: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-option:hover {
    background-color: var(--color-hover);
}

.dropdown-active {
    padding-left: 10px;
    padding-right: 30px;
    font-size: 15px;
    height: var(--select-height);
    line-height: var(--select-height);
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    color: var(--color-primary);
    font-weight: 500;
    background-image: url('/public/images/icons/select-account-down-arrow.png');
    background-size: var(--arrow-size);
    background-position: calc(100% - 12px);
    background-repeat: no-repeat;
    background-color: white;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--color-border);
    border-radius: 3px;
}

.dropdown-list {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-color: white;
    border-radius: 0;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--color-border);
    overflow-y: auto;
    max-height: 400px;
}

.dropdown-option-selected {
    background-color: #ddd;
}

.dropdown-option-selected:hover {
    background-color: #ddd;
}

.dropdown-list::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.dropdown-list::-webkit-scrollbar-track {
    background: #c0c0c0;
    border-radius: 10px;
}

/* Handle */
.dropdown-list::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

/* Handle on hover */
.dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #989898;
}
