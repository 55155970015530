.distributions-container {
    position: relative;
    width: 48%;
    overflow: visible;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 3px;
    margin-right: 50px !important;
}

.distributions-chart-container {
    /* margin-top: 20px; */
}

.distributions-header {
    display: flex;
    justify-content: left;
}

.distributions-header h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-primary);
}

.distributions-header img {
    height: 25px;
    margin-right: 15px;
}

.distributions-header span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: var(--color-dark-gray);
    margin-left: auto;
}

.dist-bars-filled-container {
    display: flex;
    margin-top: 10px;
}

.dist-bar-filled {
    height: 15px;
}

.dist-legend {
    margin-top: 40px;
    width: 100%;
    height: fit-content;
}

.legend-item {
    display: inline-flex;
    justify-content: left;
    height: 25px;
    margin-right: 20px;
}

.legend-item div {
    width: 13px;
    height: 13px;
    margin-right: 8px;
    margin-top: 4px;
}

.legend-item label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-dark-gray);
}

@media (max-width: 480px) {
    .distributions-header h1 {
        font-size: 22px;
    }
}
