.main {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bulkTrans_dragging {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-link);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 95%;
}

.bulkTrans_docIcon_white {
    padding: 85px 70px;
    background-image: url('../../../../../public/images/icons/document_white.png');
    background-position: center;
    background-size: cover;
}

// .bulkTrans_header_title {
//     color: var(--color-primary);
//     font-size: 24px;
//     font-weight: 700;
// }

// .bulkTrans_docList {
//     width: 815px;
//     height: 315px;
//     overflow: hidden;
//     position: relative;
//     border: 1px solid var(--color-lighter-gray);
// }

// .bulkTrans_docList_header {
//     height: 35px;
//     background-color: var(--color-primary);
//     color: white;
//     display: flex;
//     align-items: center;
// }

// .bulkTrans_docList_header > div {
//     color: white;
//     margin-left: 15px;
//     margin-right: 15px;
// }

// .bulkTrans_docList-body {
//     height: calc(100% - 35px);
// }

// .bulkTrans_row {
//     height: 50px;
//     border-bottom: 1px solid var(--color-accent);
//     display: flex;
//     align-items: center;
//     margin-left: 15px;
//     margin-right: 15px;
//     overflow: hidden;
// }

// .bulkTrans_row_name {
//     position: absolute;
//     left: 50px;
//     width: 370px;
//     width: calc(100% - 100px);
//     -webkit-line-clamp: 2;
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
// }

// .bulkTrans_check {
//     background-color: var(--c);
//     width: calc(var(--w) * 1px);
//     aspect-ratio: 1;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .bulkTrans_check > div {
//     border: 2px solid white;
//     border-right: none;
//     border-top: none;
//     width: calc(var(--w) / 2.5 * 1px);
//     height: calc(var(--w) / 5 * 1px);
//     transform: rotate(-40deg);
//     margin-top: -4px;
// }

// .bulkTrans_close {
//     position: absolute;
//     top: 27px;
//     right: 27px;
//     padding: 13px;
//     background-image: url('../../../../../public/images/icons/closeBlue.png');
//     background-position: center;
//     background-size: cover;
//     cursor: pointer;
// }

// .bulkTrans_docIcon_blue {
//     padding: 85px 70px;
//     background-image: url('../../../../../public/images/icons/document_blue.png');
//     background-position: center;
//     background-size: cover;
// }

// .bulkTrans_deleteIcon {
//     background-image: url('../../../../../public/images/icons/closeGray.png');
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;
// }

// /////////////////////
// ///
// ///

// .bulkDoc_close {
//     position: absolute;
//     top: 27px;
//     right: 27px;
// }

// .bulkDoc_docIcon_blue {
//     padding: 85px 70px;
//     background-image: url('../../../../../public/images/icons/document_blue.png');
//     background-position: center;
//     background-size: cover;
// }

// .bulkDoc_docIcon_white {
//     padding: 85px 70px;
//     background-image: url('../../../../../public/images/icons/document_white.png');
//     background-position: center;
//     background-size: cover;
// }
