.docViewMenu-container {
    display: flex;
    /* align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: 100%; */
    /* width: 100%; */
    /* scroll-behavior: smooth; */

    /* Add the following styles to enable scrolling */
    /* overflow: auto; */
    /* This allows vertical scrolling when content overflows */
    /* max-height: 100vh; */
    /* Set a maximum height to limit the container's height */
}

.docViewMenu-menu {
    background-color: #f1f1f1;
}

.docViewMenu-item {
    /* padding: 8px 12px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    padding: 0px 10px;
}

.docViewMenu-item:hover {
    background-color: #ddd;
}

.docViewMenu-item-selected {
    background-color: #ddd;
}
