.mngDistributions_dueMetContainer {
    display: inline-block;
    margin-left: 20px;
    font-size: 18px !important;
    color: var(--color-text-light);
    font-weight: 500;
}

.mngDistributions_dueMetItem {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.mngDistributions_dueMetItem:hover {
    color: var(--color-link-hover);
}

.mngDistributions_dueMetSelected {
    display: inline;
    text-decoration: none;
    color: var(--color-link);
    font-weight: 600;
}

.mngDistributions_updateButton {
    float: left;
    width: 140px;
    margin-top: -165px;
    margin-left: 60px;
}

@media (max-width: 1024px) {
    .mngDistributions_updateButton {
        margin: -20px 0 20px 30%;
    }

    .inline-cb-cell {
        display: inline-flex;
        margin-top: 15px;
        float: right;
    }

    .inline-cb-cell label {
        display: inline;
        margin-left: auto;
    }

    .inline-cb-cell .checkbox {
        display: inline-block;
        margin: 4px 10px;
    }
}
