#investment-details-table {
    margin: 0 60px;
    margin-bottom: 60px;
}

#investment-details-table h1,
#mobile-investment-details-table h1 {
    font-weight: bold;
    margin-left: 0;
    color: var(--color-primary);
    margin-bottom: 20px !important;
    font-weight: 600 !important;
}

#mobile-investment-details-table h1 {
    margin-left: 10px;
}

#investment-details-table td,
#investment-details-table th {
    padding-left: 20px;
    text-align: center;
    align-content: center;
}

#investment-details-table th {
    padding-bottom: 0px;
}

#investment-details-table tr.table-selector-row th {
    padding-top: 0px;
    padding-bottom: 2px;
}

#investment-details-table thead tr {
    border: none !important;
}

td.investment-details-type,
th.investment-details-type {
    text-align: left;
    align-content: left;
}

.mobile-investment-details-type {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0;
    color: var(--color-primary);
}

.mobile-investment-details-sum .mobile-investment-details-type {
    color: #fff;
}

tr.table-selector-row > th {
    padding-top: 2px;
    align-content: center;
    align-items: center;
    text-align: center;
}

th.gain-loss-column {
    align-content: center;
    align-items: center;
}

div.gain-loss-selector {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-around;
    color: var(--color-text-light);
    min-width: 20%;
    width: 80px;
    align-self: center;
    margin: 0 auto;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* div.gain-loss-selector:nth-child(2) {
    flex-grow: 1
} */

.gain-loss-selector-inactive {
    font-weight: 600;
}

.gain-loss-selector-active {
    font-weight: bold;
    color: var(--color-text);
}
#investment-details-table table.altx-table th.investment-details-type {
    text-align: left;
}
#investment-details-table table.altx-table td.investment-details-type {
    text-align: left;
}

/* #investment-details-table table.altx-table tr.investment-details-investment td.investment-details-type {
    padding-left: 30px;
} */

#investment-details-table tr.investment-details-investment {
    font-weight: normal;
    background-color: var(--color-accent-light);
    border-left: 4px transparent;
    cursor: pointer;
}

#investment-details-table tr.investment-details-investment:hover {
    background-color: var(--color-accent);
    border-left: 4px solid var(--color-primary);
}

#mobile-investment-details-table .mobile-investment-details-investment {
    background: rgba(211, 225, 249, 0.5);
    padding: 20px 10px;
    margin-top: 5px;
}

#investment-details-table tr.investment-details-agg {
    font-weight: bold;
}

#investment-details-table tr.investment-details-sum {
    font-weight: bold;
    background-color: var(--color-primary);
    color: white;
    transition: none;
}

.widget-flex-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.widget-header-button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 180px;
    margin-left: 0;
    margin-right: 0;
}

#mobile-investment-details-table .mobile-investment-details-sum {
    background-color: var(--color-primary);
    padding: 10px;
}

#mobile-investment-details-table .mobile-investment-details-agg {
    padding: 10px;
    border-bottom: solid 1px #ccc;
}

#mobile-investment-details-table .mobile-investment-details-sum .investment-details-dropdown-mobile span {
    color: #fff;
}

#mobile-investment-details-table .mobile-investment-details-sum .altx-mobile-table-cell span {
    color: #fff;
}

#mobile-investment-details-table .mobile-investment-details-sum .altx-mobile-table-cell {
    display: inline-block;
    width: 100px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    margin: 10px 0;
}

#mobile-investment-details-table .mobile-investment-details-agg .altx-mobile-table-cell {
    display: inline-block;
    width: 100px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0;
}

#mobile-investment-details-table .mobile-investment-details-investment .altx-mobile-table-cell {
    display: inline-block;
    width: 100px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0;
}

#mobile-investment-details-table .mobile-investment-details-sum .altx-mobile-table-cell label {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

#mobile-investment-details-table .mobile-investment-details-agg .altx-mobile-table-cell label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

#mobile-investment-details-table .mobile-investment-details-investment .altx-mobile-table-cell label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

#investment-details-table tr.investment-details-sum td {
    font-weight: bold;
    background-color: var(--color-primary);
    color: white;
    transition: none;
}

#investment-details-table tr.investment-details-sum:hover {
    border-left: 4px solid var(--color-primary);
    font-weight: bold;
    color: white;
    background-color: var(--color-primary);
}

#investment-details-table td.investment-details-dropdown {
    width: 24px;
    height: 20px;
    padding-left: 0;
}
/* #investment-details-table td.investment-details-dropdown.collapsed,
#investment-details-table td.investment-details-dropdown.expanded {
    background-image: url("/public/images/icons/golden-down-arrow.svg");
    background-position: left center;
    background-repeat: no-repeat;
} */

.inv-perf-admin-review-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-feature-settings: 'liga' off;
    color: var(--color-dark-gray);
    height: 100%;
}

.inv-perf-admin-review-desc span {
    position: absolute;
    margin-left: 50px;
    margin-top: -7px !important;
}

.investment-details-dropdown {
    background-position: 8px center;
    background-repeat: no-repeat;
    width: 24px;
    height: 16px;
}

.investment-details-dropdown-mobile {
    background-position: left;
    background-repeat: no-repeat;
    width: 24px;
    height: 16px;
    margin-bottom: 10px;
}

.investment-details-dropdown-mobile span {
    position: absolute;
    left: 30px;
}

.investment-details-dropdown.collapsed {
    background-image: url('/public/images/icons/golden-down-arrow.svg');
}

.investment-details-dropdown.expanded {
    background-image: url('/public/images/icons/golden-up-arrow.svg');
}

.investment-details-dropdown-mobile.collapsed {
    background-image: url('/public/images/icons/golden-down-arrow.svg');
}

.investment-details-dropdown-mobile.expanded {
    background-image: url('/public/images/icons/golden-up-arrow.svg');
}

#investment-details-table td.investment-details-gain {
    color: var(--color-dark-green);
}

#investment-details-table td.investment-details-loss {
    color: var(--color-dark-red);
}

#investment-details-table tr.investment-details-sum .investment-details-gain {
    color: white;
}

#investment-details-table tr.investment-details-sum .investment-details-loss {
    color: white;
}

@media (max-width: 480px) {
    .performance .cashFlowComponent {
        margin-bottom: 0;
    }

    #investment-details-table {
        margin: 0;
        padding: 20px;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .performance .cashFlowComponent {
        margin-bottom: 0;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .performance .cashFlowComponent {
        margin-bottom: 0;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .performance .cashFlowComponent {
        margin-bottom: 0;
    }
}
