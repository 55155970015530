/*Widget tables*/
h1.monthly-returns-header {
    font-size: 24px;
}

.monthly-returns-table tbody tr:nth-child(even) {
    background-color: #f1f5fb;
    border: none !important;
}

.monthly-returns-table tbody tr .widget-table-data-null {
    margin-left: 400px;
}

.widget-table {
    font-size: 18px;
    width: 100%;
}

.widget-table thead th {
    font-weight: 600;
    height: 40px;
    text-align: center;
}

.widget-table tbody th {
    font-weight: normal;
    text-align: left;
    padding-left: 6px;
    height: 40px;
}

.widget-table tbody td {
    text-align: center;
    height: 40px;
}

.widget-table th {
    padding: 0;
}

.widget-table td {
    font-weight: 600;
}

.widget-table-data-positive {
    color: var(--color-dark-green);
}

.widget-table-data-negative {
    color: var(--color-dark-red);
}
