.main {
    .likelyMatchItem {
        display: flex;
        align-items: center;
        background-color: white;
        margin: 10px;
        box-shadow: var(--box-shadow);

        .icon {
            margin-right: 15px;
            margin-left: 15px;
        }
    }

    .icon {
        &:hover {
            transform: scale(1.1);
        }
    }

    .isCurFilter {
        path: {
            fill: red;
        }
    }
}
