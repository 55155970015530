.docProcess-container {
    position: relative;
    /* height: 90%; */
    /* background-color: cyan; */
}

.docProcess-header {
    margin: 0px 60px 20px 60px;
    padding-top: 20px;
}

.docProcess_filters {
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
}

.docProcess_filterTitle {
    /* white-space: nowrap; */
    font-size: 18px;
    font-weight: 600;
    /* color: var(--color-primary); */
    margin-bottom: 15px;
}

.docProcess_filter {
    /* background-color: pink; */
    padding: 20px;
    margin: 10px;
    border-radius: 4px;
    box-shadow: var(--dark-box-shadow);
    cursor: pointer;
    border: 1px solid var(--color-lighter-gray);
    /* width: 180px; */
}

.docProcess_filter:hover {
    background-color: var(--color-lighter-gray);
    border: 1px solid var(--color-primary);
}

.docProcess_filterStat {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.docProcess_filter_selected {
    border: 1px solid var(--color-link);
    background-color: var(--color-link);
    color: white;
}

.docProcess_filter_selected * {
    color: white;
}

.docProcess_filter:hover.docProcess_filter_selected {
    background-color: var(--color-link);
}

.docProcess_documentViewer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-lighter-gray);
    z-index: 1;
}

.docProcess_filters2 div {
    padding: 6px;
}

.docProcess_filters2 .docProcess_filterTitle {
    margin-bottom: 0px;
}

.docProcess-gearIcon {
    /* margin-left: 5px; */
    margin-right: 10px;
}

.docProcess-gearIcon.true path {
    fill: var(--color-dark-green);
}

.docProcess-gearIcon.false path {
    fill: var(--color-dark-gray);
}
