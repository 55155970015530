.perf-export-pdf-button {
    /* --margin-horiz: 20px; */
    position: relative;
    height: 35px;
    /* width: calc(100% - var(--margin-horiz) * 2); */
    /* margin-left: var(--margin-horiz); */
    /* margin-top: var(--margin-horiz); */
    z-index: 1;
}

.perf-export-pdf-button button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.performance-page-pdf-export {
    --base-font-size: max(1em, 22px);
}

.performance-page-pdf-export .capture-investment-details-page,
.performance-page-pdf-export .investment-details-performance-table,
.performance-page-pdf-export .PortfolioOverview_component,
.performance-page-pdf-export .donutLegend {
    p,
    div,
    span,
    li,
    a,
    label,
    td {
        font-size: var(--base-font-size);
    }
}

.performance-page-pdf-export .donutText {
    div:first-child {
        font-size: max(1em, 46px);
    }
    div:last-child {
        font-size: var(--base-font-size);
    }
}
