.forbidden-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    background-color: var(--color-primary);
}

.forbidden-content {
    width: 50%;
    background-color: #fff;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.forbidden-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.forbidden-message {
    font-size: 1.2em;
    color: #737373;
}
