.draggableDivider_container {
    display: flex;
    flex-direction: row;
    /* background-color: aqua; */
    background-color: #f5f5f5;
    background-color: #fff;
    background-color: var(--color-lighter-gray);
    z-index: 100;
    border: 2px solid var(--color-light-gray);
}
