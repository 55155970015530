.main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .table {
        border-collapse: separate;
        border-spacing: 0;
        // width: 100%;
        border-left: 1px solid var(--color-dark-gray);
        th,
        td {
            border: 1px solid var(--color-dark-gray);
            border-left: none;
            text-align: left;
            padding: 3px 15px;
        }
    }
}
