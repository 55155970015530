h1.performance-summary-header {
    font-size: 24px;
    /* margin-bottom: 30px !important; */
}

h1.account-performance-header {
    font-size: 18px;
}

.performance-summary-stat {
    font-weight: normal;
}

.performance-summary-selector {
    font-size: 16px;
}

table.account-performance-summary-table {
    box-sizing: border-box;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    /* height: 258px; */
}

table.account-performance-summary-table thead {
    width: 100%;
}

table.account-performance-summary-table tr {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 0;
    height: 40px;
}

#performance-summary-header-row > td:last-child {
    overflow: visible;
}

table.account-performance-summary-table td {
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* text-align: center;
    align-items: center; */
}

.performance-summary-name {
    text-align: left;
    align-items: left;
    width: 70%;
}

.performance-summary-amount {
    text-align: right;
    align-items: right;
    /* font-weight: 300; */
    width: 40%;
}

table.account-performance-summary-table th {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* text-align: center;
    align-items: center; */
}

table.account-performance-summary-table th:first-child {
    /* text-align: left;
    align-items: left; */
}

table.account-performance-summary-table th:last-child {
    /* text-align: right;
    align-items: right; */
    /* width: 30%; */
}

td.performance-summary-negative-amount {
    color: var(--color-dark-red);
}

td.performance-summary-positive-amount {
    color: var(--color-dark-green);
}

div.account-performance-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

a.performance-see-all {
    color: var(--color-link);
}
