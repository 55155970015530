.drawer {
    position: fixed;
    top: 0;
    right: 0px;
    height: 100%;
    background: var(--color-accent);
    overflow-x: hidden;
    /* overflow-y: scroll; */
    padding: var(--drawer-pad);
    transition: transform 0.3s;
    animation: 0.3s drawerOpen;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow-scrolling: unset;
    box-shadow: -10px 0 40px -5px rgba(0, 0, 0, 0.1);
    border-left: solid 1px #eee;
    z-index: 10000 !important;
    width: var(--drawer-width);
}

.drawer-content::-webkit-scrollbar {
    display: none;
}

.drawer-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.sm {
    width: 350px;
}

.lg {
    width: 500px;
}

@keyframes drawerOpen {
    0% {
        right: -440px;
    }
    100% {
        right: 0px;
    }
}

.drawer-content {
    position: inherit;
    margin: auto;
    top: 120px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px;
    text-align: center;
    z-index: 4000 !important;
}

.drawer-content input {
    width: 100%;
}

.drawer-content label {
    text-align: left;
    margin-top: 10px;
}

button.disabled {
    background-color: #c7c7c7;
    cursor: default;
    transition: none;
}

button.disabled:hover {
    background-color: #c7c7c7;
    cursor: default;
}

.altx-drawer-infobox {
    background-color: white;
    border-radius: 3px;
    padding: 8px;
}

.altx-drawer-section {
    text-align: left;
    margin-bottom: 3px;
    font-size: 15px;
}

.altx-drawer-infobox-maintext {
    display: flex;
    justify-content: center;
    gap: 6px;
}

.drawer-checkbox {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 5px;
}

.drawer-checkbox p {
    font-size: 14px;
}

.drawer-checkbox .checkbox {
    height: 16px;
    width: 16px;
}

.drawerViewer {
    background-color: var(--color-background);
    position: fixed;
    top: 0;
    bottom: 0;
    left: calc(var(--sidebar-width) + 2 * var(--sidebar-horiz-pad));
    right: calc(var(--drawer-width) + 2 * var(--drawer-pad));
    /* overflow: scroll; */
}

@media (max-width: 480px) {
    .drawer {
        width: 100vw;
        padding: 0px;
        z-index: 10000 !important;
    }
    .lg {
        width: 100%;
        z-index: 3000 !important;
    }

    .drawer-content {
        z-index: 3000;
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .drawer {
        width: 100vw;
        z-index: 10000 !important;
    }
    .lg {
        width: 100%;
        z-index: 3000 !important;
    }

    .drawer-content {
        z-index: 3000;
        width: calc(100% + 60px);
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .drawer {
        /* width: 100vw; */
        z-index: 10000 !important;
        top: 100px;
    }
    .lg {
        position: absolute;
        width: 100%;
        z-index: 3000 !important;
    }

    .drawer-content {
        width: calc(100% + 60px);
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .drawer {
        /* width: 100vw; */
        z-index: 10000 !important;
        top: 100px;
    }
    .lg {
        width: 100%;
        /* z-index: 7000 !important; */
    }

    .drawer-content {
        z-index: 3000;
        width: calc(100% + 60px);
    }
}
