.notes_component {
    --font-color-darkGray: var(--color-dark-gray);
    --font-color-lightGray: #969696;
    --pad: 15px;
    --circle-size: 17px;

    --metWidth: var(--circle-size);
    --dueDateWidth: 60px;
    --infoWidth: calc(100% - var(--circle-size) - var(--dueDateWidth) - var(--pad) * 3);

    margin: 20px 0px;
    position: relative;
}

/* header */
.notes_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
}

.notes_headerText {
    font-size: 16px;
    color: var(--color-primary);
    width: 80%;
    font-weight: 700;
}

.notes_SeeAll {
    color: var(--color-link);
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
    text-align: right;
}

/* footer */
.notes_seeAll span {
    cursor: pointer;
}

/* main container */
.notes_row {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--color-lighter-gray);
}

.notes_editing {
    margin-left: 5px;
    cursor: unset;
}

.notes_editing:hover {
    box-shadow: none;
}

.notes_notEditing {
    cursor: pointer;
}

/* note row */
.notes_row:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

/* note row - editing */
.notes_editBtns {
    position: absolute;
    right: 20px;
    display: flex;
}

/* note row - not editing */
.notes_view {
    cursor: pointer;
    z-index: 1000;
    position: relative;
    margin-left: 10px;
    margin-right: 20px;
    width: 100%;
    display: flex;

    left: 0;
    float: left;
    text-align: left;
    cursor: pointer;
}

.notes_open {
    /* height: unset; */
    height: auto;
}

.notes_row_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 80%;
    height: 22px;
    overflow: hidden;
}

.notes_row_body > div {
    width: 100%;
    margin: 0;
}

.notes_row_body iframe {
    width: 100%;
    /* height: 500px; */
    border: none;
}

.notes_row_body ul,
.notes_row_body ol {
    /* margin-block-start: 1em;
    margin-block-end: 1em; */
    padding-inline-start: 40px;
    margin: 0;
}

.notes_row_body div {
    /* right: 0; */
}

.notes_row_body p {
    text-align: left;
    margin: 0;
}

.notes_row_dueDate {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    height: 100%;
}

.notes_row_dueDate .edit-icon {
    margin-right: 20px;
    border-radius: 50%;
    padding: 10px;
}

.notes_row_dueDate .edit-icon:hover {
    transform: scale(1.3);
    transition: all 0.2s ease-in-out;
}
